<template>
  <div id="Profil__Container__Info">
    <div id="Profil__Container__Info__Logo">
      <img src="../../../static/images/icon_membre@2x.png" alt="icon_membre" />
      <div id="Profil__Container__Info__Logo__Edit" @click="openEdit">
        <img
          src="../../../static/images/icon_edit_rose@2x.png"
          alt="icon_edit_rose"
        />
      </div>
    </div>
    <div id="Profil__Container__Info__Members">
      <p>
        <strong>{{ user.Lastname }} {{ user.Firstname }}</strong>
      </p>
      <p>Abonné {{ user.subscription }}</p>
      <p>{{ user.Email }}</p>
      <span
        ><img src="../../../static/images/picto_objectif.png" />
        <p>{{ this.user.goal }}</p></span
      >
    </div>
    <div id="Profil__Container__Info__Spec">
      <div id="Profil__Container__Info__Spec__Top">
        <span>{{ user.Gender === 0 ? "Homme" : "Femme" }}</span>
        <span>{{ returnAge }} ans</span>
        <span>{{ user.Weight }} kg</span>
        <span>{{ returnHeight() }} cm</span>
      </div>
      <div id="Profil__Container__Info__Spec__Bottom">
        <span>IMC {{ returnIMC }}</span>
        <span>{{ returnFC }} FC max théorique</span>
      </div>
    </div>
    <div id="Profil__Container__Info__Spec__Button">
      <button
        :class="{ selected: this.$store.state.home.showActivitiesProfil }"
        @click="onChangeTypeToActivities"
      >
        Activité
      </button>
      <button
        :class="{ selected: this.$store.state.home.showBilanProfil }"
        @click="onChangeTypeToBilan"
      >
        Bilan
      </button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onChangeTypeToBilan() {
      this.$store.state.home.showActivitiesProfil = false;
      this.$store.state.home.showBilanProfil = true;
    },
    onChangeTypeToActivities() {
      this.$store.state.home.showActivitiesProfil = true;
      this.$store.state.home.showBilanProfil = false;
    },
    returnHeight() {
      if (this.user.Height > 120) {
        return this.user.Height;
      } else {
        return this.user.Height * 100;
      }
    },
    openEdit() {
      this.$store.state.home.showEditMember = true;
    }
  },
  computed: {
    returnAge() {
      const currentDate = new Date();
      const birthdayDate = new Date(this.user.BirthDate);
      return currentDate.getFullYear() - birthdayDate.getFullYear();
    },
    returnIMC() {
      const weight = this.user.Weight;
      const height = this.user.Height;
      const result = weight / (height * height);
      return Math.round(result);
    },
    returnFC() {
      const currentDate = new Date();
      const birthdayDate = new Date(this.user.BirthDate);
      const age = currentDate.getFullYear() - birthdayDate.getFullYear();
      const gender = this.user.Gender === 1 ? 226 : 220;

      return gender - age;
    }
  },
  props: {
    user: { type: Object }
  }
};
</script>
