<template>
  <div id="ActivityRanking" class="Activity__Container">
    <h2>Activité</h2>
    <div id="ActivityRanking__Container">
      <div id="ActivityRanking__Container__Title">
        <img src="../../../../static/images/picto_classement.png" alt="" />
        <p>Classement</p>
      </div>
      <div id="ActivityRanking__Container__Ranked">
        <div id="ActivityRanking__Container__Ranked--top">
          {{ rankingTop }}<sup>e</sup>
        </div>
        <div id="ActivityRanking__Container__Ranked--separed"></div>
        <div id="ActivityRanking__Container__Ranked--bottom">
          {{ rankingBottom }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  beforeMount() {
    const rank = this.activities[0] ? this.activities[0].rank : "0/0";
    const splitRank = rank.split("/");

    this.rankingTop = splitRank[0];
    this.rankingBottom = splitRank[1];
  },
  data() {
    return {
      rankingTop: 0,
      rankingBottom: 0
    };
  },
  props: {
    activities: { type: Array }
  }
};
</script>
