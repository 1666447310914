<template>
  <div id="Marketing__TopPosts" class="Marketing__Container">
    <h2 class="Marketing__Container__Title">
      Les Tops posts du moment
    </h2>
    <div id="Marketing__TopPosts__Container">
      <div
        class="Marketing__TopPosts__Container__SubContainer"
        v-for="(post, index) in arrayPosts"
        :key="index"
      >
        <img
          class="Marketing__TopPosts__Container__SubContainer__Image"
          :src="post.LienImage"
          alt=""
        />
        <p class="Marketing__TopPosts__Container__SubContainer__Score">
          {{ formatValueAndEmoji(post) }}
        </p>
        <p class="Marketing__TopPosts__Container__SubContainer__Description">
          {{ post.Description }}
        </p>
        <ShareNetwork
          network="facebook"
          :url="post.LienImage"
          :title="post.Nom"
          :description="post.Description"
          :quote="formatDescriptionFacebook(post)"
          hashtags=""
        >
          <img src="../../../static/images/partager-facebook.png" alt="" />
        </ShareNetwork>
      </div>
    </div>
  </div>
</template>

<script>
import { emojis } from "vue-chat-emoji";
export default {
  beforeMount() {
    this.idPost.forEach(id => {
      const findPost = this.marketing.filter(p => p.id === id);
      this.arrayPosts.push(findPost[0]);
    });
  },
  data() {
    return {
      arrayPosts: [],
      idPost: [4, 21, 22, 23]
    };
  },
  methods: {
    formatValueAndEmoji(post) {
      let text = "";

      text += Math.round(post.Valeur);

      if (post.Emoji.length > 0) {
        post.Emoji.forEach(emo => (text += emojis.decodeEmoji(emo)));
      }

      return text;
    },
    formatDescriptionFacebook(post) {
      return `${Math.round(post.Valeur)} - ${post.Description}`;
    }
  },
  props: {
    marketing: { type: Array }
  }
};
</script>
