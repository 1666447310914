<template>
  <div id="Evols__AgePhysio" class="ContainerCharts__Evols">
    <div id="Evols__AgePhysio__Header">
      <h2>Âge physiologique</h2>
      <div id="Evols__AgePhysio__Header__Filter">
        <img
          src="../../../../../static/images/icon_filtre_bleu@2x.png"
          alt=""
        />
        <p>Filtrer par session :</p>
        <select v-model="selected" @change="updateCharts">
          <option value="3">3 derniers bilans</option>
          <option value="6">6 derniers bilans</option>
          <option value="-1">Tous</option>
        </select>
      </div>
    </div>
    <div id="Evols__AgePhysio__Charts">
      <apexchart
        type="line"
        ref="activityChart"
        height="300"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
require("dayjs/locale/fr");
dayjs.locale("fr");
export default {
  name: "index",
  beforeMount() {
    if (this.bilan.length > 0) {
      for (let i = 0; i < 3; i++) {
        if (
          this.bilan[i] &&
          this.bilan[i].physioAge &&
          this.bilan[i].physioAge !== -1
        ) {
          this.series[0].data.push(this.bilan[i].physioAge);
          this.chartOptions.xaxis.categories.push(
            dayjs(this.bilan[i].startDate).format("DD MMM YYYY")
          );
        }
      }
      this.series[0].data.reverse();
      this.chartOptions.xaxis.categories.reverse();
    }
  },
  data() {
    return {
      selected: "3",
      series: [
        {
          name: "ÂGE PHYSIOLOGIQUE",
          data: []
        }
      ],
      chartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: ["#fa8282"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        markers: {
          size: 5
        },

        legend: {
          show: true,
          horizontalAlign: "left",
          fontSize: "14px",
          fontWeight: 600,
          markers: {
            width: 15,
            height: 15,
            strokeColor: "#fff",
            radius: 12
          },
          itemMargin: {
            horizontal: 10,
            vertical: 15
          }
        }
      }
    };
  },
  methods: {
    updateCharts() {
      this.chartOptions.xaxis.categories = [];
      this.series[0].data = [];
      const stopI =
        this.selected !== "-1" ? Number(this.selected) : this.bilan.length;

      if (this.bilan.length > 0) {
        for (let i = 0; i < stopI; i++) {
          if (
            this.bilan[i] &&
            this.bilan[i].physioAge &&
            this.bilan[i].physioAge !== -1
          ) {
            this.series[0].data.push(this.bilan[i].physioAge);
            this.chartOptions.xaxis.categories.push(
              dayjs(this.bilan[i].startDate).format("DD MMM YYYY")
            );
          }
        }

        this.$refs.activityChart.updateOptions({
          xaxis: {
            categories: this.chartOptions.xaxis.categories.reverse()
          }
        });
        this.$refs.activityChart.updateSeries(
          [
            {
              name: "ÂGE PHYSIOLOGIQUE",
              data: this.series[0].data.reverse()
            }
          ],
          true,
          true
        );
      }
    }
  },
  props: {
    bilan: { type: Array }
  }
};
</script>
