<template>
  <div id="Help">
    <div id="Help__Container">
      <h2>
        Vous avez besoin d'une assistance
        <br />
        sur l'utilisation de Diagopro?
      </h2>
      <div id="Help__Container__Info">
        <div id="Help__Container__Info--Text">
          <p>Contactez-nous</p>
          <p>Email: <strong>contact@diagoapp.fr</strong></p>
          <p>Ligne direct: <strong>04 94 88 28 26</strong></p>
        </div>
        <div id="Help__Container__Info--Image">
          <img
            src="../../static/images/icon_besoin_aide@2x.png"
            alt="img help"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
