<template>
  <div id="Profil" v-if="isLoad">
    <header>
      <div id="Profil__Return" @click="handleReturnToListMembers">
        <img src="../../static/images/icon-back-bleu@2x.png" alt="" />
        Revenir à la liste des membres
      </div>
      <Info :user="this.user" />
    </header>
    <aside>
      <EditMember
        :user="this.user"
        v-if="this.$store.state.home.showEditMember"
      />
    </aside>
    <main>
      <Bilan
        :bilan="bilan"
        v-if="
          !this.$store.state.home.showEditMember &&
            this.$store.state.home.showBilanProfil
        "
      />
      <Activity
        :activities="activities"
        v-if="
          !this.$store.state.home.showEditMember &&
            this.$store.state.home.showActivitiesProfil
        "
      />
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Info from "./Info";
import Bilan from "./Bilan";
import Activity from "./Activity";
import EditMember from "../ManageUsers/editMember";
import dayjs from "dayjs";

export default {
  components: { Info, Bilan, EditMember, Activity },
  async beforeMount() {
    this.$loading(true);
    const user = this.$store.state.users.allUsers.filter(
      user => user.user.Id === this.$store.state.home.profilSelected
    );

    const center = {
      CenterName: localStorage.getItem("centerName"),
      StructureName: localStorage.getItem("structureName")
    };

    this.user = user[0].user;
    this.user.goal = user[0].goal;
    this.user.subscription = user[0].subscription;

    //All activities for all users
    const allActivities = await axios.post(
      "https://www.upulse.fr/ConnectDiagoInteropServiceHost/api/connect/diago/monthlyEvolutionDiagoPro",
      {
        center,
        userId: this.$store.state.home.profilSelected
      }
    );
    this.activities = allActivities.data;

    //Fetch data bilan
    const respBilan = await axios.post(
      "https://www.upulse.fr/ConnectDiagoInteropServiceHost/api/connect/diago/evolutionBilanBackOffice",
      {
        center,
        userId: this.$store.state.home.profilSelected
      }
    );

    respBilan.data.filter(el => {
      if (
        el.physioAge !== "NaN" &&
        !isNaN(el.physioAge) &&
        el.physioAge !== "NaN3"
      ) {
        this.bilan.push(el);
      }
    });

    this.bilan.sort((a, b) => (a.startDate > b.startDate ? -1 : 1));
    this.activities.sort((a, b) =>
      dayjs()
        .month(a.month - 1)
        .year(a.year) >
      dayjs()
        .month(b.month - 1)
        .year(b.year)
        ? -1
        : 1
    );

    this.$loading(false);
    this.isLoad = true;
  },
  methods: {
    handleReturnToListMembers() {
      this.$store.commit("home/resetAllStatesHome");
      this.$store.commit("home/changeValueStates", {
        name: "MembersState",
        value: true
      });
    }
  },
  data() {
    return {
      user: {},
      bilan: [],
      activities: [],
      isLoad: false
    };
  }
};
</script>
