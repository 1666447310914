<template>
  <div id="Marketing__Thematique" class="Marketing__Container">
    <h2 class="Marketing__Container__Title">Postez selon une thématique</h2>
    <div id="Marketing__Thematique__Container">
      <div id="Marketing__Thematique__Container__Form">
        <form>
          <select @change="handleChangeThematique">
            <option value="" disabled selected
              >Choisissez votre thématique</option
            >
            <option
              v-for="(them, index) in arrayThematique"
              :key="index"
              :value="them"
              >{{ them }}</option
            >
          </select>
          <select
            :disabled="arrayVariable.length < 1"
            @change="handleChangeVariable"
          >
            <option value="" disabled selected>Choisissez une variable</option>
            <option
              v-for="(varia, index) in arrayVariable"
              :key="index"
              :value="varia"
              >{{ varia }}</option
            >
          </select>
          <select
            :disabled="arrayVariable.length < 1"
            @change="handleChangePediode"
          >
            <option value="" disabled selected>Choisissez une période</option>
            <option value="true|false">Semaine en cours</option>
            <option value="false|false">Semaine dernière</option>
            <option value="true|true">Mois en cours</option>
            <option value="false|true">Mois dernier</option>
          </select>
          <button :disabled="buttonDisabled" @click="handleShowPost">
            AFFICHER LE POST
          </button>
        </form>
      </div>
      <div id="Marketing__Thematique__Container__Preview">
        <h2 class="Marketing__Container__Title">Aperçu de votre post</h2>
        <div id="Marketing__Thematique__Container__Preview__Top">
          <img :src="srcImage" alt="" />
          <img
            src="../../../static/images/publi_select_no.png"
            alt=""
            v-if="!loadPost"
          />
          <p v-else>
            <span>{{ formatValueAndEmoji() }}</span>
            {{ postSelected.Description }}
          </p>
        </div>
        <div id="Marketing__Thematique__Container__Preview__Bottom">
          <p>
            <img src="../../../static/images/icon_partager_blue.png" alt="" />
            Publier sur votre page Facebook
          </p>
          <ShareNetwork
            network="facebook"
            :url="postSelected.LienImage"
            :title="postSelected.Nom"
            :description="postSelected.Description"
            :quote="formatDescriptionFacebook()"
            hashtags=""
            v-if="loadPost"
          >
            <img src="../../../static/images/partager-facebook.png" alt="" />
          </ShareNetwork>
          <div v-else class="share-network-facebook notSelectedImage">
            <img src="../../../static/images/partager-facebook.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imageEmpty from "../../../static/images/img_empty.png";
import { fetchMarketing } from "../../../utils/fnc/request";
import { emojis } from "vue-chat-emoji";
export default {
  beforeMount() {
    this.marketing.forEach(post => {
      if (!this.arrayThematique.includes(post.Categorie))
        this.arrayThematique.push(post.Categorie);
    });
  },
  data() {
    return {
      arrayThematique: [],
      thematique: null,
      arrayVariable: [],
      variable: null,
      periode: null,
      buttonDisabled: true,
      loadPost: false,
      postSelected: {},
      srcImage: imageEmpty
    };
  },
  methods: {
    formatValueAndEmoji() {
      let text = "";

      text += Math.round(this.postSelected.Valeur);

      if (this.postSelected.Emoji.length > 0) {
        this.postSelected.Emoji.forEach(
          emo => (text += emojis.decodeEmoji(emo))
        );
      }

      return text;
    },
    formatDescriptionFacebook() {
      return `${Math.round(this.postSelected.Valeur)} - ${
        this.postSelected.Description
      }`;
    },
    async handleShowPost(e) {
      e.preventDefault();
      if (
        this.thematique !== null &&
        this.variable !== null &&
        this.periode !== null
      ) {
        const [current, month] = [...this.periode.split("|")];
        const results = await fetchMarketing(
          current === "true",
          month === "true"
        );
        const data = results.data;
        this.postSelected = data.filter(
          d => d.Categorie === this.thematique && d.Nom === this.variable
        )[0];
        this.loadPost = true;
        this.srcImage = this.postSelected.LienImage;
      }
    },
    changeStateButton() {
      this.buttonDisabled = !(
        this.thematique !== null &&
        this.variable !== null &&
        this.periode !== null
      );
    },
    handleChangeThematique(e) {
      this.thematique = e.target.value;
      this.arrayVariable = [];

      this.marketing.forEach(post => {
        if (
          post.Categorie === this.thematique &&
          !this.arrayVariable.includes(post.Nom)
        )
          this.arrayVariable.push(post.Nom);
      });

      this.changeStateButton();
    },
    handleChangeVariable(e) {
      this.variable = e.target.value;
      this.changeStateButton();
    },
    handleChangePediode(e) {
      this.periode = e.target.value;
      this.changeStateButton();
    }
  },
  props: {
    marketing: { type: Array }
  }
};
</script>
