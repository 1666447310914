<template>
  <div id="RepCardio" class="ContainerCharts__Repartitions">
    <header>
      <h2>Répartition des zones de travail cardio</h2>
      <div id="RepCardio__Filter" class="filter">
        <img
          src="../../../../../static/images/icon_filtre_bleu@2x.png"
          alt=""
        />
        <p>Filtrer par période :</p>
        <select v-model="selectedTime" @change="updateCharts">
          <option value="3">3 mois</option>
          <option value="6">6 mois</option>
          <option value="-1">Tout</option>
        </select>
      </div>
    </header>
    <div id="RepCardio__Charts">
      <apexchart
        type="bar"
        ref="activityChart"
        height="400"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);
export default {
  beforeMount() {
    const currentMonth = dayjs();

    if (this.activities.length > 0) {
      for (let i = 0; i < this.activities.length; i++) {
        const scopeDate = dayjs(currentMonth)
          .month(Number(this.activities[i].month) - 1)
          .year(this.activities[i].year);

        if (
          currentMonth.diff(scopeDate, "month") <= 2 &&
          currentMonth.diff(scopeDate, "month") >= 0
        ) {
          const hr1 = this.activities[i].hr1
            ? Number(this.activities[i].hr1)
            : 0;
          const hr2 = this.activities[i].hr2
            ? Number(this.activities[i].hr2)
            : 0;
          const hr3 = this.activities[i].hr3
            ? Number(this.activities[i].hr3)
            : 0;
          const hr4 = this.activities[i].hr4
            ? Number(this.activities[i].hr4)
            : 0;
          const total = hr1 + hr2 + hr3 + hr4;

          this.series[0].data.push(this.percentage(hr1, total));
          this.series[1].data.push(this.percentage(hr2, total));
          this.series[2].data.push(this.percentage(hr3, total));
          this.series[3].data.push(this.percentage(hr4, total));

          this.chartOptions.xaxis.categories.push(
            dayjs(scopeDate)
              .format("MMM YYYY")
              .toUpperCase()
          );
        }
      }

      for (let i = 0; i < 4; i++) {
        this.series[i].data.reverse();
      }
      this.chartOptions.xaxis.categories.reverse();
    }
  },
  data() {
    return {
      selectedTime: "3",
      series: [
        {
          name: "Basse intensité",
          data: []
        },
        {
          name: "Brûle graisse",
          data: []
        },
        {
          name: "Endurance",
          data: []
        },
        {
          name: "Haute intensité",
          data: []
        }
      ],
      chartOptions: {
        chart: {
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "75%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ["#D5CCCC", "#FF925C", "#FA8282", "#B44C4C"],
        xaxis: {
          categories: [],
          axisBorder: {
            show: true,
            color: "#333745",
            height: 1,
            width: "100%"
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#333745",
            height: 6
          }
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return `${value}%`;
            }
          },
          max: 100,
          tickAmount: 5
        },
        markers: {
          size: 5
        },
        legend: {
          show: true,
          horizontalAlign: "left",
          fontFamily: "Montserrat, Arial",
          fontSize: "13px",
          offsetY: 30,
          fontWeight: 400,
          labels: {
            colors: "#333745FF",
            useSeriesColors: false
          },
          markers: {
            width: 25,
            height: 25,
            radius: 50
          },
          itemMargin: {
            horizontal: 10,
            vertical: 30
          }
        }
      }
    };
  },
  methods: {
    percentage(partialValue, totalValue) {
      return Math.round((100 * partialValue) / totalValue);
    },
    updateCharts() {
      const currentMonth = dayjs();
      this.chartOptions.xaxis.categories = [];

      for (let i = 0; i < 4; i++) {
        this.series[i].data = [];
      }

      const stopDate =
        Number(this.selectedTime) !== -1 ? Number(this.selectedTime) : 1000;

      if (this.activities.length > 0) {
        for (let i = 0; i < this.activities.length; i++) {
          const scopeDate = dayjs(currentMonth)
            .month(Number(this.activities[i].month) - 1)
            .year(this.activities[i].year);

          if (
            currentMonth.diff(scopeDate, "month") <= stopDate &&
            currentMonth.diff(scopeDate, "month") >= 0
          ) {
            const hr1 = this.activities[i].hr1
              ? Number(this.activities[i].hr1)
              : 0;
            const hr2 = this.activities[i].hr2
              ? Number(this.activities[i].hr2)
              : 0;
            const hr3 = this.activities[i].hr3
              ? Number(this.activities[i].hr3)
              : 0;
            const hr4 = this.activities[i].hr4
              ? Number(this.activities[i].hr4)
              : 0;
            const total = hr1 + hr2 + hr3 + hr4;

            this.series[0].data.push(this.percentage(hr1, total));
            this.series[1].data.push(this.percentage(hr2, total));
            this.series[2].data.push(this.percentage(hr3, total));
            this.series[3].data.push(this.percentage(hr4, total));

            this.chartOptions.xaxis.categories.push(
              dayjs(scopeDate)
                .format("MMM YYYY")
                .toUpperCase()
            );
          }
        }

        this.$refs.activityChart.updateOptions({
          xaxis: {
            categories: this.chartOptions.xaxis.categories.reverse()
          }
        });
        this.$refs.activityChart.updateSeries(
          [
            {
              name: "Basse intensité",
              data: this.series[0].data.reverse()
            },
            {
              name: "Brûle graisse",
              data: this.series[1].data.reverse()
            },
            {
              name: "Endurance",
              data: this.series[2].data.reverse()
            },
            {
              name: "Haute intensité",
              data: this.series[3].data.reverse()
            }
          ],
          true,
          true
        );
      }
    }
  },
  props: {
    activities: { type: Array }
  }
};
</script>
