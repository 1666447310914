<template>
  <div id="InWork">
    <div id="InWork__Images">
      <img src="../../static/images/Pickaxe.png" alt="Pickaxe" />
      <img src="../../static/images/rock.png" alt="Pickaxe" />
    </div>
    <h2>Page en construction</h2>
    <p>Merci de votre compréhension !</p>
  </div>
</template>

<script>
export default {
  name: "index"
};
</script>
