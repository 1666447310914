<template>
  <div id="SearchMember">
    <div id="SearchMember__Container">
      <img
        src="../../../static/images/close.png"
        alt="close img"
        id="close"
        @click="close"
      />
      <img src="../../../static/images/icon_search.png" alt="search img" />
      <label>
        <input
          type="text"
          placeholder="Entrez un nom, prénom, âge..."
          required
          @change="handleChangeSearch"
        />
      </label>
      <button @click="searchAction">OK</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleChangeSearch(e) {
      this.$store.state.home.textSearch = e.target.value;
    },
    close() {
      this.$store.commit("home/changeShowSearchMember", false);
    }
  },
  props: {
    searchAction: { type: Function }
  }
};
</script>
