<template>
  <div id="ThisMonth" class="col-span-3 lg:col-span-2">
    <p>Ce mois-ci</p>
    <div id="ThisMonth__Container">
      <div id="ThisMonth__Container__Donut">
        <apexchart
          type="donut"
          :options="this.optionChart"
          :series="this.series"
          width="250"
        ></apexchart>
      </div>
      <div id="ThisMonth__Container__Members">
        <p id="ThisMonth__Container__Members__Total">
          {{ userThisMonth }} <span>Membres</span>
        </p>
        <p id="ThisMonth__Container__Members__Freemium">
          <img src="../../../static/images/rose.png" alt="circlePink" />
          <span>
            <strong>{{ this.current.diagoFreemiums }}</strong>
            Freemium
            <span class="colorPink"> ({{ calculFreemium }}) </span>
          </span>
        </p>
        <p id="ThisMonth__Container__Members__Premium">
          <img src="../../../static/images/carmin.png" alt="circlePinkDark" />
          <span>
            <strong>{{ returnDiago }}</strong>
            Premium
            <span class="colorOrange"> ({{ calculDiago }}) </span>
          </span>
        </p>
      </div>
      <div id="ThisMonth__Container__Info">
        <p>
          {{ Math.round(numberBilan.Valeur) }}
          <span>
            Heures <br />
            d’entrainement
          </span>
        </p>
        <p>
          {{ Math.round(numberSeances.Valeur) }}
          <span>
            Séances <br />
            réalisées
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log(this.numberBilan);
  },
  computed: {
    userThisMonth() {
      return (
        this.current.diagoSubscribedUsers.length +
        this.current.diagoPackUsers.length +
        this.current.diagoCenterOfferUsers.length +
        this.current.diagoFreemiums
      );
    },
    calculFreemium() {
      return this.current.diagoFreemiums - this.last.diagoFreemiums;
    },
    calculDiago() {
      return (
        this.current.diagoSubscribedUsers.length +
        this.current.diagoPackUsers.length +
        this.current.diagoCenterOfferUsers.length -
        (this.last.diagoSubscribedUsers.length +
          this.last.diagoPackUsers.length +
          this.last.diagoCenterOfferUsers.length)
      );
    },
    returnDiago() {
      return (
        this.current.diagoSubscribedUsers.length +
        this.current.diagoPackUsers.length +
        this.current.diagoCenterOfferUsers.length
      );
    }
  },
  data() {
    return {
      numberBilan: this.marketing.filter(p => p.id === 21)[0],
      numberSeances: this.marketing.filter(p => p.id === 22)[0],
      optionChart: {
        chart: {
          type: "donut",
          animations: {
            enabled: false
          }
        },
        colors: ["#d36b6b", "#fa8282"],
        labels: ["Premium", "Freemium"],
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        }
      },
      series: [
        this.current.diagoSubscribedUsers.length +
          this.current.diagoPackUsers.length +
          this.current.diagoCenterOfferUsers.length,
        this.current.diagoFreemiums
      ]
    };
  },

  props: {
    users: { type: Array },
    current: { type: Object },
    marketing: { type: Array },
    last: { type: Object }
  }
};
</script>
