<template>
  <div id="ManageUsers__EditUser">
    <div id="ManageUsers__EditUser__GlobalContainer">
      <div id="ManageUsers__EditUser__Close">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="times-circle"
          class="svg-inline--fa fa-times-circle fa-w-16"
          role="img"
          viewBox="0 0 512 512"
          @click="closeEditMember"
        >
          <path
            fill="currentColor"
            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
          />
        </svg>
      </div>
      <div id="ManageUsers__EditUser__Container">
        <div id="ManageUsers__EditUser__Container__Form">
          <img src="../../../static/images/icon_membre.png" alt="" />
          <form class="grid grid-cols-2 " @submit="handleSubmit">
            <div>
              <input
                type="text"
                placeholder="Nom*"
                v-model="surname"
                required
              />
              <input
                type="text"
                placeholder="Prénom*"
                v-model="name"
                required
              />
              <input type="text" placeholder="Email*" v-model="email" />
              <div id="birthday">
                <label>Date de naissance*</label>
                <input type="date" v-model="birthday" required />
              </div>
            </div>
            <div>
              <input
                type="text"
                placeholder="Poids (en kg)*"
                v-model="weight"
                required
              />
              <input
                type="text"
                placeholder="Taille (en cm)*"
                v-model="size"
                required
              />
              <select
                name="genre"
                id="genre"
                @change="handleChangeSelect"
                v-model="gender"
              >
                <option value="0">Homme</option>
                <option value="1">Femme</option>
              </select>
            </div>
            <button type="submit" class="col-span-3">Editer le membre</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      birthday: this.returnDate(this.user.user.BirthDate),
      name: this.user.user.Firstname,
      email: this.user.user.Email,
      surname: this.user.user.Lastname,
      gender: this.user.user.Gender,
      weight: this.user.user.Weight,
      size: this.returnHeight()
    };
  },
  methods: {
    returnHeight() {
      if (this.user.user.Height > 120) {
        return this.user.user.Height;
      } else {
        return this.user.user.Height * 100;
      }
    },
    returnDate(date) {
      const arrayDate = date.split("T");
      arrayDate.splice(1, 1);
      return arrayDate[0];
    },
    closeEditMember() {
      this.$store.state.home.showEditMember = false;
    },
    handleChangeSelect(e) {
      this.gender = e.target.value;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.$loading(true);
      const button = document.querySelector(
        "#ManageUsers__EditUser__Container__Form > form > button"
      );
      button.disabled = true;
      axios
        .post(
          "https://www.upulse.fr/ConnectDiagoInteropServiceHost/api/connect/diago/updateUser",
          {
            centerId: {
              CenterName: localStorage.getItem("centerName"),
              StructureName: localStorage.getItem("structureName")
            },
            user: {
              Id: this.user.user.Id,
              Firstname: this.name,
              Lastname: this.surname,
              Gender: this.gender,
              MuscularLevel: this.user.user.MuscularLevel,
              CardioLevel: this.user.user.CardioLevel,
              BirthDate: this.birthday + "T00:00:00",
              Weight: this.weight,
              Height: Number(this.size) / 100,
              DeactivationDate: this.user.user.DeactivationDate,
              Email: this.email,
              Avatar: this.user.user.Avatar,
              Motivations: this.user.user.Motivations,
              LastTestDate: this.user.user.LastTestDate,
              SensorMac: this.user.user.SensorMac,
              CPoints: this.user.user.CPoints,
              CRank: this.user.user.CRank,
              CUsersCount: this.user.user.CUsersCount
            }
          }
        )
        .then(response => {
          if (response.status === 200) {
            this.$store.state.users.allUsers.find(user => {
              if (user.user.Id === this.user.user.Id) {
                user.user.Lastname = this.surname;
                user.user.Firstname = this.name;
                user.user.Weight = Number(this.weight);
                user.user.Height = Number(this.size) / 100;
                user.user.BirthDate = this.birthday + "T00:00:00";
                user.user.Gender = Number(this.gender);
              }
            });

            this.user.user.Lastname = this.surname;
            this.user.user.Firstname = this.name;
            this.user.user.Weight = Number(this.weight);
            this.user.user.Height = Number(this.size) / 100;
            this.user.user.BirthDate = this.birthday + "T00:00:00";
            this.user.user.Gender = Number(this.gender);
          }
          this.$loading(false);
          this.$store.state.home.showEditMember = false;
        })
        .catch(() => {
          this.$loading(false);
          button.disabled = false;
          alert("Erreur d'enregistrement");
        });
    }
  },
  props: {
    user: { type: Object }
  }
};
</script>
