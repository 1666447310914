const Home = {
  namespaced: true,

  state: () => ({
    allUsers: []
  }),

  mutations: {
    setAllUsers(state, users) {
      state.allUsers = users;
    }
  },

  actions: {
    actionSetAllUsers(context, users) {
      context.commit("setAllUsers", users);
    }
  }
};

export default Home;
