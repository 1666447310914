<template>
  <div id="Repartition" class="Activity__Container">
    <h2>Répartitions</h2>
    <RepActivity :activities="activities" />
    <RepCardio :activities="activities" />
    <RepMuscu :activities="activities" />
  </div>
</template>

<script>
import RepActivity from "./RepActivity";
import RepCardio from "./RepCardio";
import RepMuscu from "./RepMuscu";
export default {
  components: { RepActivity, RepCardio, RepMuscu },
  name: "index",
  props: {
    activities: { type: Array }
  }
};
</script>
