<template>
  <div id="Bilan__Analyse">
    <h2>Analyse par bilan</h2>
    <div id="Bilan__Analyse__Filter">
      <div id="Bilan__Analyse__Filter__Title">
        <img
          src="../../../../static/images/icon_filtre_bleu@2x.png"
          alt="icon_filtre_bleu"
        />
        <span><strong>Filter par session :</strong></span>
      </div>
      <div id="Bilan__Analyse__Filter__Select">
        <select @change="onSelectionChange">
          <option v-for="(test, index) in bilan" :key="index" :value="index">{{
            returnDate(test)
          }}</option>
        </select>
      </div>
    </div>
    <div id="Bilan__Analyse__Apex">
      <h3>Cartographie</h3>
      <apexchart
        type="radar"
        height="400"
        ref="realtimeChart"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
    <p>
      Pour chaque catégorie, la note affichée sur 100 est calculée par rapport à
      la catégorie d'âge et le sexe de la personne
    </p>
  </div>
</template>

<script>
import dayjs from "dayjs";
require("dayjs/locale/fr");
dayjs.locale("fr");
export default {
  beforeMount() {
    const selected = this.$store.state.home.bilanSelected;
    if (this.bilan.length > 0) {
      const bilan = this.bilan[selected];

      if (bilan && bilan.cardioScore) {
        this.series[1].data = [
          bilan.cardioScore ? bilan.cardioScore : 0,
          bilan.muscuScore ? bilan.muscuScore : 0,
          bilan.souplesseScore ? bilan.souplesseScore : 0,
          bilan.equilibreScore ? bilan.equilibreScore : 0,
          bilan.profilNerveuxScore ? bilan.profilNerveuxScore : 0
        ];

        if (this.bilan.length > 1) {
          const bilan2 = this.bilan[selected + 1];
          this.series[0].data = [
            bilan2.cardioScore ? bilan2.cardioScore : 0,
            bilan2.muscuScore ? bilan2.muscuScore : 0,
            bilan2.souplesseScore ? bilan2.souplesseScore : 0,
            bilan2.equilibreScore ? bilan2.equilibreScore : 0,
            bilan2.profilNerveuxScore ? bilan2.profilNerveuxScore : 0
          ];
        }
      }
    }
  },
  data() {
    return {
      selected: 0,
      chartOptions: {
        legend: {
          show: false
        },
        chart: {
          id: "realtimeChart",
          height: 300,
          type: "radar",
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: [
            "Cardio",
            "Musculaire",
            "Souplesse",
            "Équilibre",
            "Profil nerveux"
          ],
          labels: {
            show: true,
            style: {
              colors: ["#fa8282", "#fa8282", "#fa8282", "#fa8282", "#fa8282"],
              fontSize: "13px",
              fontWeight: 600
            }
          }
        },
        fill: {
          opacity: [0.1, 0.5],
          colors: ["#777", "#fa8282"]
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#777", "#fa8282"],
          dashArray: 0
        },
        markers: {
          size: 4,
          colors: ["#777", "#fa8282"],
          hover: {
            size: 12,
            sizeOffset: 5
          }
        }
      },
      series: [
        {
          name: "Séssion précédente",
          data: [0, 0, 0, 0, 0]
        },
        {
          name: "Dernière séssion",
          data: [0, 0, 0, 0, 0]
        }
      ]
    };
  },
  methods: {
    returnDate(test) {
      const startDate = dayjs(test.startDate).format("DD-MMM");
      const endDate = dayjs(test.endDate).format("DD-MMM");
      const yearDate = dayjs(test.endDate).format("YYYY");

      return `${startDate} - ${endDate} ${yearDate}`;
    },
    onSelectionChange(e) {
      const selected = Number(e.target.value);
      this.$store.state.home.bilanSelected = e.target.value;

      if (this.bilan[selected]) {
        const bilan = this.bilan[selected];
        this.series[1].data = [
          bilan.cardioScore ? bilan.cardioScore : 0,
          bilan.muscuScore ? bilan.muscuScore : 0,
          bilan.souplesseScore ? bilan.souplesseScore : 0,
          bilan.equilibreScore ? bilan.equilibreScore : 0,
          bilan.profilNerveuxScore ? bilan.profilNerveuxScore : 0
        ];

        if (this.bilan[selected + 1]) {
          const bilan2 = this.bilan[selected + 1];
          this.series[0].data = [
            bilan2.cardioScore ? bilan2.cardioScore : 0,
            bilan2.muscuScore ? bilan2.muscuScore : 0,
            bilan2.souplesseScore ? bilan2.souplesseScore : 0,
            bilan2.equilibreScore ? bilan2.equilibreScore : 0,
            bilan2.profilNerveuxScore ? bilan2.profilNerveuxScore : 0
          ];
        }
      }

      this.$refs.realtimeChart.updateSeries(
        [
          {
            data: this.series[0].data
          },
          {
            data: this.series[1].data
          }
        ],
        true,
        true
      );
    }
  },
  props: {
    bilan: { type: Array }
  }
};
</script>
