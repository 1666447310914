<template>
  <div id="ManageUsers">
    <h2>Gestion des membres</h2>
    <div id="ManageUsers__Container">
      <header>
        <div class="ManageUsers__Container__TopButton">
          <img
            src="../../static/images/icon_membre.png"
            alt="logo user"
            @click="showAddMember"
          />
          <p>Ajouter un membre</p>
        </div>
        <div class="ManageUsers__Container__TopButton">
          <img
            src="../../static/images/icon_search.png"
            alt="logo user"
            @click="showSearchMember"
          />
          <p>Rechercher un membre</p>
        </div>
      </header>
      <section id="ManageUsers__Container__Filters">
        <div id="ManageUsers__Container__Filters__Title">
          <img
            src="../../static/images/icon_filtre_rose.png"
            alt="img filter"
          />
          <p>Filtrer par:</p>
        </div>
        <div id="ManageUsers__Container__Filters__Filter">
          <form>
            <label>
              <input
                type="checkbox"
                value="Diago"
                @change="e => filters('Diago', e)"
              />
              Abonnés premium
            </label>
            <label>
              <input
                type="checkbox"
                value="Freemium"
                @change="e => filters('Freemium', e)"
              />
              Abonnés freemium
            </label>
            <label>
              <input
                type="checkbox"
                value="Back Office"
                @change="e => filters('Back Office', e)"
              />
              Provenance Back office
            </label>
            <label>
              <input
                type="checkbox"
                value="Telephone"
                @change="e => filters('Telephone', e)"
              />
              Provenance Back téléphone
            </label>
          </form>
        </div>
      </section>
      <a
        href="/"
        id="linkSup"
        v-if="usersSelected.length !== 0"
        @click="openDeleteModal"
        ><img
          src="../../static/images/icon_supprimer.png"
          alt="ico delete"
        /><span>Supprimer un/plusieurs membres</span></a
      >
      <section id="ManageUsers__Container__ListMembers">
        <table>
          <thead>
            <tr>
              <th>
                <label>
                  <input type="checkbox" @change="selectAll" />
                </label>
              </th>
              <th>
                Prénom
              </th>
              <th>
                Nom
              </th>
              <th>
                Email
              </th>
              <th>
                Abonnement
              </th>
              <th>
                Ajouté dans
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(user, index) in arrayShow.slice(startShow, limitsShow)"
              :key="index"
            >
              <td>
                <label>
                  <input
                    type="checkbox"
                    @change="e => checkedProfil(user, e)"
                  />
                </label>
              </td>
              <td>{{ user.user.Firstname }}</td>
              <td>{{ user.user.Lastname }}</td>
              <td>{{ user.user.Email }}</td>
              <td>{{ user.subscription }}</td>
              <td>{{ user.origine }}</td>
              <td>
                <a href="/" @click="e => selectProfil(user, e)">PROFIL</a>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
    <footer>
      <div id="ManageUsers__Select">
        <label for="ligne">Ligne par page</label>
        <select name="ligne" id="ligne" @change="limitPage">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="*">Tout</option>
        </select>
      </div>
      <div id="ManageUsers__ChoicePage">
        <p>
          {{ startShow }}-{{ limitsShow }} sur {{ arrayShow.length }}
          <img
            src="../../static/images/icon-back2-bluelight.png"
            alt="img arrow left"
            @click="changePlageSelect('left')"
          />
          <img
            src="../../static/images/icon-next2-bluelight.png"
            alt="img arrow right"
            @click="changePlageSelect('right')"
          />
        </p>
      </div>
    </footer>
    <AddMember v-if="this.$store.state.home.showAddMember" />
    <DeleteMembers
      v-if="this.$store.state.home.showDeleteUsers"
      :members="usersSelected"
      :users="users"
      :unselect="unselectAll"
    />
    <SearchMember
      v-if="this.$store.state.home.showSearchMember"
      :searchAction="searchAction"
    />
  </div>
</template>

<script>
import AddMember from "./addMember";
import SearchMember from "./SearchMember";
import DeleteMembers from "./deleteUsers";
export default {
  components: { AddMember, SearchMember, DeleteMembers },
  beforeMount() {
    if (this.$store.state.home.textSearch !== "") {
      this.searchAction();
    }
  },
  data() {
    return {
      arrayShow: this.$store.state.users.allUsers,
      lastFilterSelected: null,
      startShow: 0,
      limitsShow: 10,
      selectValue: 10,
      usersSelected: [],
      showDeleteMember: false,
      users: this.$store.state.users.allUsers
    };
  },
  methods: {
    filters(type, e) {
      const currentSelect = e.target.value;

      if (this.lastFilterSelected === currentSelect) {
        this.lastFilterSelected = null;
        return (this.arrayShow = this.users);
      }

      this.lastFilterSelected = type;
      const containerCheckbox = document.querySelector(
        "#ManageUsers__Container__Filters__Filter > form"
      );

      const allCheckboxFilter = containerCheckbox.querySelectorAll(
        "label > input"
      );

      allCheckboxFilter.forEach(el => {
        if (el.value !== currentSelect) {
          el.checked = false;
        }
      });

      switch (type) {
        case "Diago":
          this.arrayShow = this.users.filter(
            user => user.subscription === "Diago"
          );
          break;
        case "Freemium":
          this.arrayShow = this.users.filter(
            user => user.subscription === "Freemium"
          );
          break;
        case "Back Office":
          this.arrayShow = this.users.filter(
            user => user.origine === "Back Office"
          );
          break;
        case "Telephone":
          this.arrayShow = this.users.filter(
            user => user.origine === "Telephone"
          );
          break;
      }
    },
    limitPage(e) {
      const value = e.target.value;

      if (value === "*") {
        return (this.limitsShow = this.arrayShow.length);
      } else {
        this.limitsShow = Number(value);
        this.selectValue = Number(value);
      }
    },
    changePlageSelect(direction) {
      switch (direction) {
        case "right":
          if (this.limitsShow >= this.arrayShow.length) {
            return;
          } else {
            this.startShow = this.startShow + this.selectValue;
            this.limitsShow = this.limitsShow + this.selectValue;
          }
          break;

        case "left":
          if (this.startShow === 0) {
            return;
          } else {
            this.startShow = this.startShow - this.selectValue;
            this.limitsShow = this.limitsShow - this.selectValue;
          }
          break;
      }
    },
    checkedProfil(user, e) {
      if (e.target.checked) {
        //const indexArrayUsers = this.users.indexOf(user);
        const exist = this.usersSelected.find(el => el === user);
        if (!exist) {
          this.usersSelected.push(user);
        }
      } else {
        const indexArrayUsers = this.usersSelected.indexOf(user);
        this.usersSelected.splice(indexArrayUsers, 1);
      }
    },
    selectAll(e) {
      const isChecked = e.target.checked;
      const selectorList = document.querySelectorAll(
        "#ManageUsers__Container__ListMembers > table > tbody > tr"
      );
      if (!isChecked) {
        e.target.checked = false;
        selectorList.forEach(el => {
          const checkbox = el.querySelector(
            "td:nth-child(1) > label > input[type=checkbox]"
          );
          checkbox.checked = false;
        });
        this.usersSelected = [];
      } else {
        e.target.checked = true;
        selectorList.forEach(el => {
          const checkbox = el.querySelector(
            "tr > td:nth-child(1) > label > input[type=checkbox]"
          );
          checkbox.checked = true;
        });
        this.usersSelected = this.arrayShow.slice(
          this.startShow,
          this.limitsShow
        );
      }
    },
    unselectAll() {
      const selectorList = document.querySelectorAll(
        "#ManageUsers__Container__ListMembers > table > tbody > tr"
      );
      selectorList.forEach(el => {
        const checkbox = el.querySelector(
          "td:nth-child(1) > label > input[type=checkbox]"
        );
        checkbox.checked = false;
      });
      this.usersSelected = [];
    },
    showAddMember() {
      this.$store.commit("home/changeShowAddMember", true);
    },
    showSearchMember() {
      this.$store.commit("home/changeShowSearchMember", true);
    },
    searchAction() {
      let result = [];

      const searchStoreValue = this.$store.state.home.textSearch;
      result = this.users.filter(
        user =>
          user.user["Lastname"].toLowerCase() === searchStoreValue.toLowerCase()
      );

      if (result.length === 0) {
        result = this.users.filter(
          user =>
            user.user["Firstname"].toLowerCase() ===
            searchStoreValue.toLowerCase()
        );
      }

      this.arrayShow = result;
      this.$store.commit("home/changeValueStates", {
        name: "showSearchMember",
        value: false
      });
    },
    selectProfil(user, e) {
      e.preventDefault();
      this.$store.state.home.profilSelected = user.user.Id;
      this.$store.commit("home/showPageProfil");
    },
    openDeleteModal(e) {
      e.preventDefault();
      this.$store.state.home.showDeleteUsers = !this.$store.state.home
        .showDeleteUsers;
    }
  }
};
</script>
