<template>
  <div id="Activity">
    <Ranking :activities="activities" />
    <Sollicitations :activities="activities" />
    <FollowActivity :activities="activities" />
    <Repartitions :activities="activities" />
  </div>
</template>

<script>
import Ranking from "./Ranking";
import Sollicitations from "./Sollicitations";
import FollowActivity from "./FollowActivity";
import Repartitions from "./Repartitions";
export default {
  components: { Ranking, Sollicitations, FollowActivity, Repartitions },
  data() {
    return {};
  },
  methods: {},
  props: {
    activities: { type: Array }
  }
};
</script>
