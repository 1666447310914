<template>
  <div id="Evols">
    <h2>Évolution</h2>
    <AgePhysio :bilan="bilan" />
    <Cardio :bilan="bilan" />
    <Muscu :bilan="bilan" />
    <Equilibre :bilan="bilan" />
    <Souplesse :bilan="bilan" />
    <ProfNerveux :bilan="bilan" />
  </div>
</template>

<script>
import AgePhysio from "./AgePhysio";
import Cardio from "./Cardio";
import Muscu from "./Muscu";
import Equilibre from "./Equilibre";
import Souplesse from "./Souplesse";
import ProfNerveux from "./ProfNerv";
export default {
  name: "Evols",
  components: { AgePhysio, Cardio, Muscu, Equilibre, Souplesse, ProfNerveux },
  data() {
    return {};
  },
  methods: {},
  props: {
    bilan: { type: Array }
  }
};
</script>
