<template>
  <div id="Bilan__Detail">
    <h2>Résultats détaillés</h2>
    <div id="Bilan__Detail__Container">
      <div id="Bilan__Detail__Cardio">
        <h3>Cardio</h3>
        <table>
          <thead>
            <tr>
              <th>INDICE</th>
              <th>VALEUR</th>
              <th>NOTE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>VO2 Max</td>
              <td>
                {{ formatValue("vO2maxValeur") }}
              </td>
              <td>
                {{ formatValue("vO2maxNote") }}
              </td>
            </tr>
            <tr>
              <td>Adaptation à l'effort</td>
              <td>
                {{ formatValue("adaptationValeur") }}
              </td>
              <td rowspan="2">
                {{ formatValue("adaptRecupNote") }}
              </td>
            </tr>
            <tr>
              <td>Récupération à l'effort</td>
              <td>
                {{ formatValue("recuperationValeur") }}
              </td>
            </tr>
            <tr>
              <td>VMA</td>
              <td>
                {{ formatValue("vma") }}
              </td>
              <td>.</td>
            </tr>
            <tr>
              <td>PMA</td>
              <td>
                {{ formatValue("pma") }}
              </td>
              <td>.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="Bilan__Detail__Muscu">
        <h3>Musculaire</h3>
        <table>
          <thead>
            <tr>
              <th>INDICE</th>
              <th>VALEUR</th>
              <th>NOTE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Endurance force pompes</td>
              <td>
                {{ formatValue("nbPompesValeur") }}
              </td>
              <td>
                {{ formatValue("nbPompesNote") }}
              </td>
            </tr>
            <tr>
              <td>Tonacité (détente en cm)</td>
              <td>
                {{ formatValue("detenteVerticaleValeur") }}
              </td>
              <td>
                {{ formatValue("detenteVerticaleNote") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="Bilan__Detail__Eq">
        <h3>Équilibre</h3>
        <table>
          <thead>
            <tr>
              <th>INDICE</th>
              <th>VALEUR</th>
              <th>NOTE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Muscle posturaux</td>
              <td>
                {{ formatValue("musclesPosturauxValeur") }}
              </td>
              <td rowspan="2">
                {{ formatValue("muscuVisuNote") }}
              </td>
            </tr>
            <tr>
              <td>Dépendance visuelle</td>
              <td>
                {{ formatValue("dependanceVisuelleValeur") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="Bilan__Detail__Nerv">
        <h3>Profil Nerveux</h3>
        <table>
          <thead>
            <tr>
              <th>INDICE</th>
              <th>VALEUR</th>
              <th>NOTE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Résistance au stress</td>
              <td>
                {{ formatValue("resistanceAuStressValeur") }}
              </td>
              <td>
                {{ formatValue("resistanceAuStressNote") }}
              </td>
            </tr>
            <tr>
              <td>Capacité de relaxation</td>
              <td>
                {{ formatValue("capaciteRelaxation") }}
              </td>
              <td>.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="Bilan__Detail__Soup">
        <h3>Souplesse</h3>
        <table>
          <thead>
            <tr>
              <th>INDICE</th>
              <th>VALEUR</th>
              <th>NOTE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Rachis lombaire</td>
              <td>
                {{ formatValue("souplesseRachisValeur") }}
              </td>
              <td>
                {{ formatValue("souplesseRachisNote") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Detail",
  beforeMount() {},
  data() {
    return {};
  },
  methods: {
    formatValue(key) {
      let val = this.bilan[this.$store.state.home.bilanSelected]
        ? this.bilan[this.$store.state.home.bilanSelected][key]
          ? this.bilan[this.$store.state.home.bilanSelected][key]
          : 0
        : 0;

      if (val < 0) {
        val = ".";
      }

      return val;
    }
  },
  props: {
    bilan: { type: Array }
  }
};
</script>
