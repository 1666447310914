<template>
  <div id="Board" class="grid grid-cols-3 gap-4 auto-rows-min">
    <h2 class="col-span-3 ">Tableau de bord</h2>
    <ThisMonth
      :users="users"
      :current="current"
      :last="last"
      :marketing="marketing"
    />
    <DirectAccess />
    <Finance :users="users" :current="current" />
    <Speetch />
  </div>
</template>

<script>
import ThisMonth from "./ThisMonth";
import DirectAccess from "./DirectAccess";
import Finance from "./Finance";
import Speetch from "./Speetch";
export default {
  components: { ThisMonth, DirectAccess, Finance, Speetch },
  data() {
    return {
      users: this.$store.state.users.allUsers
    };
  },
  props: {
    current: { type: Object },
    marketing: { type: Array },
    last: { type: Object }
  }
};
</script>
