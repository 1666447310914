<template>
  <div id="Bilan">
    <header>
      <Analyse :bilan="bilan" />
      <Age :bilan="bilan" />
    </header>
    <main>
      <Detail :bilan="bilan" />
      <Evols :bilan="bilan" />
    </main>
  </div>
</template>

<script>
import Analyse from "./Analyse";
import Age from "./Age";
import Detail from "./Detail";
import Evols from "./Evols";
export default {
  beforeMount() {},
  components: { Analyse, Age, Detail, Evols },
  data() {
    return {};
  },
  methods: {},
  props: {
    bilan: { type: Array }
  }
};
</script>
