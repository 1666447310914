<template>
  <div id="MenuBar">
    <div id="MenuBar__Header">
      <img src="../../static/images/logo_diagopro_small.png" alt="logo" />
      <img src="../../static/images/logo_diagopro_D2x.png" alt="logo" />
    </div>
    <ul>
      <li>
        <a
          href="/"
          :class="returnClassLinkActiveState('boardState')"
          @click="e => handleChangeState('boardState', e)"
        >
          Tableau de bord
        </a>
        <img
          v-if="!checkLinkActiveState('boardState')"
          src="../../static/images/icon_dashboard@2x.png"
          alt="logo"
        />
        <img
          v-else
          src="../../static/images/icon_dashboard_select@2x.png"
          alt="logo"
        />
      </li>
      <li>
        <a
          href="/"
          :class="returnClassLinkActiveState('MembersState')"
          @click="e => handleChangeState('MembersState', e)"
        >
          Gestion des membres
        </a>
        <img
          v-if="!checkLinkActiveState('MembersState')"
          src="../../static/images/icon_membres@2x.png"
          alt="logo"
        />
        <img
          v-else
          src="../../static/images/icon_membres_select@2x.png"
          alt="logo"
        />
      </li>
      <li>
        <a
          href="/"
          :class="returnClassLinkActiveState('financeState')"
          @click="e => handleChangeState('financeState', e)"
        >
          Outils financiers
        </a>
        <img
          v-if="!checkLinkActiveState('financeState')"
          src="../../static/images/icon_finance@2x.png"
          alt="logo"
        />
        <img
          v-else
          src="../../static/images/icon_finance_select@2x.png"
          alt="logo"
        />
      </li>
      <li>
        <a
          href="/"
          :class="returnClassLinkActiveState('analytiqueState')"
          @click="e => handleChangeState('analytiqueState', e)"
        >
          Données analytiques
        </a>
        <img
          v-if="!checkLinkActiveState('analytiqueState')"
          src="../../static/images/icon_analytics@2x.png"
          alt="logo"
        />
        <img
          v-else
          src="../../static/images/icon_analytics_select@2x.png"
          alt="logo"
        />
      </li>
      <li>
        <a
          href="/"
          :class="returnClassLinkActiveState('marketingState')"
          @click="e => handleChangeState('marketingState', e)"
        >
          Outil marketing
        </a>
        <img
          v-if="!checkLinkActiveState('marketingState')"
          src="../../static/images/icon_marketing@2x.png"
          alt="logo"
        />
        <img
          v-else
          src="../../static/images/icon_marketing_select@2x.png"
          alt="logo"
        />
      </li>
      <li>
        <a
          href="/"
          :class="returnClassLinkActiveState('helpState')"
          @click="e => handleChangeState('helpState', e)"
        >
          Besoin d'aide
        </a>
        <img
          v-if="!checkLinkActiveState('helpState')"
          src="../../static/images/icon_aide@2x.png"
          alt="logo"
        />
        <img
          v-else
          src="../../static/images/icon_aide_select@2x.png"
          alt="logo"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    returnClassLinkActiveState(type) {
      if (this.$store.state.home[type]) return "linkActive";
    },
    checkLinkActiveState(type) {
      return !!this.$store.state.home[type];
    },
    handleChangeState(name, e) {
      e.preventDefault();
      this.$store.commit("home/resetAllStatesHome");
      this.$store.commit("home/changeValueStates", { name, value: true });
    }
  }
};
</script>
