<template>
  <div id="Finance__MonthFinance">
    <h2>Etat financier en {{ returnMonthAndYears }}</h2>
    <div id="Finance__MonthFinance__Container">
      <div id="Finance__MonthFinance__Container__Card">
        <div class="Finance__MonthFinance__Card">
          <div class="Finance__MonthFinance__Card__Title">
            <p>Crédit en votre faveur</p>
          </div>
          <div class="Finance__MonthFinance__Card__Sum">
            <p>{{ currentMonthCredit.toFixed(2) }}€</p>
            <img
              src="../../../static/images/icon_credit_small.png"
              alt="icon_credit"
            />
          </div>
          <div class="Finance__MonthFinance__Card__Desc">
            <p>
              Ce crédit est le montant que DiagoSAS doit vous reverser. Il
              correspond aux produits et services vendus au travers de
              l’application mobile avec votre code centre.
              <br />
              <br />
              Les reversions sont de 3€HT pour un abonnement Premium, de
              16,60€HT pour un Pack 3 mois, de 30 €HT pour unPack 6 mois et de
              50 €HT pour un Pack 1 an.
              <br />
              <br />
              Pour être crédité, vous devez envoyer votre facture du montant
              indiqué à DiagoSAS par email <strong>contact@diagoapp.fr</strong>.
              <br />
              Nb:regardezle montant dès le1erdumoissuivant, au niveau de
              l’onglet «Evolution Financière» / Crédit en votre faveur
            </p>
          </div>
        </div>
        <div class="Finance__MonthFinance__Card">
          <div class="Finance__MonthFinance__Card__Title">
            <p>Débit en faveur de Diago</p>
          </div>
          <div class="Finance__MonthFinance__Card__Sum">
            <p>{{ currentMonthDebit.toFixed(2) }}€</p>
            <img
              src="../../../static/images/icon_credit_small.png"
              alt="icon_credit"
            />
          </div>
          <div class="Finance__MonthFinance__Card__Desc">
            <p>
              Ce débit est le montant que vous devez reversez à DiagoSAS pour
              l’utilisation du service applicatif, comme stipulé dans votre
              convention de partenariat.
              <br />
              <br />
              Il correspond aux membres que vous avez créé par le Back Office
              DiagoPro, suite à votre décision d’offrir le service DIAGO ou de
              le facturer au travers de votre propre ERP.
              <br />
              <br />
              Vous serez prélevez de cette somme tous les 15 du mois
              échu.Nb:Vérifiez le montant dès le1erdumoissuivant, au niveau de
              l’onglet «Evolution Financière» / Montant de votre facture
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  beforeMount() {
    if (this.month.diagoSubscribedUsers.length > 0) {
      this.currentMonthCredit += this.month.diagoSubscribedUsers.length * 3;
    }

    if (this.month.diagoCenterOfferUsers.length > 0) {
      this.currentMonthDebit += this.month.diagoCenterOfferUsers.length * 5.2;
    }

    if (this.month.diagoPackUsers.length > 0) {
      this.month.diagoPackUsers.forEach(p => {
        let typePack = p.packName.match(/\d+/);
        this.currentMonthCredit += this.price[typePack];
      });
    }
  },
  computed: {
    returnMonthAndYears() {
      const dateObject = new Date();
      let formatter = new Intl.DateTimeFormat("fr", { month: "long" });
      let objectDate = formatter.format(dateObject);
      const currentYear = dateObject.getFullYear();

      return `${objectDate} ${currentYear}`;
    }
  },
  data() {
    return {
      price: {
        1: 3,
        3: 16.6,
        6: 30
      },
      currentMonthCredit: 0,
      currentMonthDebit: 0
    };
  },
  props: {
    month: { type: Object }
  }
};
</script>
