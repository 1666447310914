<template>
  <div id="HeaderHome">
    <label>
      <input
        type="text"
        placeholder="Rechercher un membre"
        @keypress="handleKeyPress"
        v-model="searchMember"
      />
    </label>
    <p>Mon compte</p>
    <div id="HeaderHome__Arrow">
      <img
        id="HeaderHome__Arrow__Ico"
        src="../../static/images/icon_triangle_blue@2x.png"
        alt="arrow ico"
        @click="wrapperFNC"
      />
      <div id="HeaderHome__Arrow__Wrapper" class="wrapper-close">
        <ul>
          <li>
            <a href="/" @click="disconnect">se déconnecter</a>
          </li>
        </ul>
      </div>
    </div>
    <img
      id="HeaderHome__Account"
      src="../../static/images/icon_compte@2x.png"
      alt="account ico"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      wrapperOpen: false,
      searchMember: ""
    };
  },
  methods: {
    wrapperFNC(e) {
      e.preventDefault();
      const wrapper = document.getElementById("HeaderHome__Arrow__Wrapper");

      if (this.wrapperOpen) {
        wrapper.classList.remove("wrapper-open");
        wrapper.classList.add("wrapper-close");
        this.wrapperOpen = false;
      } else {
        wrapper.classList.remove("wrapper-close");
        wrapper.classList.add("wrapper-open");
        this.wrapperOpen = true;
      }
    },
    handleKeyPress(e) {
      if (e.key === "Enter") {
        this.$store.state.home.textSearch = this.searchMember;
        this.$store.commit("home/resetAllStatesHome", true);

        setTimeout(() => {
          this.$store.state.home.MembersState = true;
        }, 1);
      }
    },
    disconnect(e) {
      e.preventDefault();
      localStorage.clear();
      this.$store.commit("home/resetAllState");
      window.location.href = "/";
    }
  }
};
</script>
