<template>
  <div id="RepFinance">
    <div id="RepFinance__Header">
      <h2>
        Répartition des finances <br />
        par type de membre premium
      </h2>
      <div id="RepFinance__Header__Filter">
        <p>
          <img
            src="../../../static/images/icon_filtre_bleu.png"
            alt="icon_filtre"
          />
          Filtrer par période
        </p>

        <select @change="updateChart">
          <option value="3">3 mois</option>
          <option value="6">6 mois</option>
          <option value="1">1 ans</option>
        </select>
      </div>
    </div>
    <div id="RepFinance__CheckBoxContainer">
      <form>
        <label>
          <input
            type="checkbox"
            value="*"
            @change="handleChangeCheck"
            checked
          />
          Tous
        </label>
        <label>
          <input
            type="checkbox"
            value="BackOffice"
            @change="handleChangeCheck"
          />
          Premium Back office
        </label>
        <label>
          <input type="checkbox" value="Diago" @change="handleChangeCheck" />
          Premium App
        </label>
        <label>
          <input type="checkbox" value="3" @change="handleChangeCheck" />
          Pack 3 mois
        </label>
        <label>
          <input type="checkbox" value="6" @change="handleChangeCheck" />
          Pack 6 mois
        </label>
        <label>
          <input type="checkbox" value="1" @change="handleChangeCheck" />
          Pack 1 ans
        </label>
      </form>
    </div>
    <div id="RepFinance__Chart">
      <apexchart
        type="line"
        ref="activityChart"
        height="400"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.updateChart();
  },
  data() {
    return {
      price: {
        1: 3,
        3: 16.6,
        6: 30
      },
      currentCheck: "*",
      series: [
        {
          name: "Premium Back Office",
          data: []
        },
        {
          name: "Premium App",
          data: []
        },
        {
          name: "Pack 3 mois",
          data: [25, 45, 87, 45]
        },
        {
          name: "Pack 6 mois",
          data: []
        },
        {
          name: "Pack 1 ans",
          data: []
        }
      ],
      chartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: ["#b44c4c", "#d36b6b", "#ff925c", "#d96126", "#7eabbf"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        markers: {
          size: 5
        },

        legend: {
          show: true,
          horizontalAlign: "left",
          fontSize: "14px",
          fontWeight: 600,
          markers: {
            width: 15,
            height: 15,
            strokeColor: "#fff",
            radius: 12
          },
          itemMargin: {
            horizontal: 10,
            vertical: 15
          }
        }
      }
    };
  },
  methods: {
    handleChangeCheck(e) {
      const arrayCheck = document.querySelectorAll(
        "#RepFinance__CheckBoxContainer > form > label"
      );

      arrayCheck.forEach(lab => {
        const currentInput = lab.querySelector("input");
        if (currentInput !== e.target) {
          currentInput.checked = false;
        }
      });
      this.currentCheck = e.target.value;
      this.updateChart();
    },
    updateChartView(dataCat, backOffice, Diago, threemonth, sixmonth, year) {
      this.$refs.activityChart.updateOptions({
        xaxis: {
          categories: dataCat.reverse()
        }
      });
      this.$refs.activityChart.updateSeries(
        [
          {
            name: "Premium Back Office",
            data: backOffice.reverse()
          },
          {
            name: "Premium App",
            data: Diago.reverse()
          },
          {
            name: "Pack 3 mois",
            data: threemonth.reverse()
          },
          {
            name: "Pack 6 mois",
            data: sixmonth.reverse()
          },
          {
            name: "Pack 1 ans",
            data: year.reverse()
          }
        ],
        true,
        true
      );
    },
    updateChart() {
      let duration = document.querySelector(
        "#RepFinance__Header__Filter > select"
      ).value;
      const checked = this.currentCheck;
      const currentArray = [];

      //Set period
      if (duration === "1") {
        duration = this.dataFinance.length - 1;
      }

      for (let i = 0; i < duration; i++) {
        const currentEl = this.dataFinance[i];
        currentArray.push(currentEl);
      }

      // set check
      const backOffice = [];
      const Diago = [];
      const threemonth = [];
      const sixmonth = [];
      const year = [];
      const dataCat = [];

      switch (checked) {
        case "BackOffice":
          currentArray.forEach(el => {
            const dateObject = new Date(el.date);
            const Year = dateObject.getFullYear();
            let formatter = new Intl.DateTimeFormat("fr", { month: "long" });
            let Month = formatter.format(dateObject);

            backOffice.push((el.diagoCenterOfferUsers.length * 5.2).toFixed(2));
            dataCat.push(`${Month}-${Year}`);
          });
          break;
        case "Diago":
          currentArray.forEach(el => {
            const dateObject = new Date(el.date);
            const Year = dateObject.getFullYear();
            let formatter = new Intl.DateTimeFormat("fr", { month: "long" });
            let Month = formatter.format(dateObject);

            Diago.push(el.diagoSubscribedUsers.length * 3);
            dataCat.push(`${Month}-${Year}`);
          });
          break;

        case "3":
          currentArray.forEach(el => {
            const dateObject = new Date(el.date);
            const Year = dateObject.getFullYear();
            let formatter = new Intl.DateTimeFormat("fr", { month: "long" });
            let Month = formatter.format(dateObject);

            if (el.diagoPackUsers.length > 0) {
              let currentPrice = 0;

              el.diagoPackUsers.forEach(p => {
                let typePack = p.packName.match(/\d+/);
                if (typePack[0] === "3") {
                  currentPrice += this.price[typePack];
                }
              });
              threemonth.push(currentPrice);
              dataCat.push(`${Month}-${Year}`);
            }
          });
          break;
        case "6":
          currentArray.forEach(el => {
            const dateObject = new Date(el.date);
            const Year = dateObject.getFullYear();
            let formatter = new Intl.DateTimeFormat("fr", { month: "long" });
            let Month = formatter.format(dateObject);

            if (el.diagoPackUsers.length > 0) {
              let currentPrice = 0;

              el.diagoPackUsers.forEach(p => {
                let typePack = p.packName.match(/\d+/);
                if (typePack[0] === "6") {
                  currentPrice += this.price[typePack];
                }
              });
              sixmonth.push(currentPrice);
              dataCat.push(`${Month}-${Year}`);
            }
          });
          break;
        case "1":
          currentArray.forEach(el => {
            const dateObject = new Date(el.date);
            const Year = dateObject.getFullYear();
            let formatter = new Intl.DateTimeFormat("fr", { month: "long" });
            let Month = formatter.format(dateObject);

            if (el.diagoPackUsers.length > 0) {
              let currentPrice = 0;

              el.diagoPackUsers.forEach(p => {
                let typePack = p.packName.match(/\d+/);
                if (typePack[0] === "1") {
                  currentPrice += this.price[typePack];
                }
              });
              year.push(currentPrice);
              dataCat.push(`${Month}-${Year}`);
            }
          });
          break;
        case "*":
          currentArray.forEach(el => {
            const dateObject = new Date(el.date);
            const Year = dateObject.getFullYear();
            let formatter = new Intl.DateTimeFormat("fr", { month: "long" });
            let Month = formatter.format(dateObject);

            backOffice.push((el.diagoCenterOfferUsers.length * 5.2).toFixed(2));
            Diago.push(el.diagoSubscribedUsers.length * 3);
            dataCat.push(`${Month}-${Year}`);

            let currentPrice = 0;

            el.diagoPackUsers.forEach(p => {
              let typePack = p.packName.match(/\d+/);
              if (typePack[0] === "3") {
                currentPrice += this.price[typePack];
              }
            });
            threemonth.push(currentPrice);

            currentPrice = 0;

            el.diagoPackUsers.forEach(p => {
              let typePack = p.packName.match(/\d+/);
              if (typePack[0] === "6") {
                currentPrice += this.price[typePack];
              }
            });
            sixmonth.push(currentPrice);

            currentPrice = 0;

            el.diagoPackUsers.forEach(p => {
              let typePack = p.packName.match(/\d+/);
              if (typePack[0] === "1") {
                currentPrice += this.price[typePack];
              }
            });
            year.push(currentPrice);
          });
          break;
      }
      this.updateChartView(
        dataCat,
        backOffice,
        Diago,
        threemonth,
        sixmonth,
        year
      );
    }
  },
  props: {
    dataFinance: { type: Array }
  }
};
</script>
