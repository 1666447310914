<template>
  <div id="ThisMonthGenerate">
    <h2>Chiffre généré par type de membre, ce mois-ci</h2>
    <div id="Age__Chart">
      <apexchart
        type="bar"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
export default {
  beforeMount() {
    if (this.month.diagoSubscribedUsers.length > 0) {
      this.Diago += this.month.diagoSubscribedUsers.length * 3;
    }

    if (this.month.diagoCenterOfferUsers.length > 0) {
      this.PBO += this.month.diagoCenterOfferUsers.length * 5.2;
    }

    if (this.month.diagoPackUsers.length > 0) {
      this.month.diagoPackUsers.forEach(p => {
        let typePack = p.packName.match(/\d+/);
        this[typePack] += this.price[typePack];
      });
    }

    this.series[0].data = [this.PBO.toFixed(2), 0, 0, 0, 0];
    this.series[1].data = [0, this.Diago.toFixed(2), this[3], this[6], this[1]];
  },
  data() {
    return {
      PBO: 0,
      Diago: 0,
      3: 0,
      6: 0,
      1: 0,
      price: {
        1: 3,
        3: 16.6,
        6: 30
      },
      series: [
        {
          name: "Débit en cours",
          data: []
        },
        {
          name: "Crédit en cours",
          data: []
        }
      ],
      chartOptions: {
        colors: ["#d36b6b", "#fa8282"],
        chart: {
          type: "bar",
          toolbar: {
            show: false
          }
        },
        legend: {
          show: true,
          horizontalAlign: "left",
          fontSize: "14px",
          fontWeight: 600,
          markers: {
            width: 15,
            height: 15,
            strokeColor: "#fff",
            radius: 12
          },
          itemMargin: {
            horizontal: 10,
            vertical: 15
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            dataLabels: {
              position: "top" // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val + "€";
          },
          offsetY: -20,
          style: {
            fontSize: "14px",
            colors: ["#304758"],
            fontWeight: 600
          }
        },

        xaxis: {
          categories: [
            "Premium BackOffice",
            "Premium App",
            "Pack 3 mois",
            "Pack 6 mois",
            "Pack 1 ans"
          ],
          position: "bottom"
        },
        yaxis: {
          labels: {
            show: true,
            formatter: function(val) {
              return val + "€";
            }
          }
        }
      }
    };
  },
  props: {
    month: { type: Object }
  }
};
</script>
