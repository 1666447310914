<template>
  <div id="ManageUsers__AddUser">
    <div id="ManageUsers__AddUser__GlobalContainer">
      <div id="ManageUsers__AddUser__Close">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="times-circle"
          class="svg-inline--fa fa-times-circle fa-w-16"
          role="img"
          viewBox="0 0 512 512"
          @click="closeAddMember"
        >
          <path
            fill="currentColor"
            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
          />
        </svg>
      </div>
      <div id="ManageUsers__AddUser__Container">
        <div id="ManageUsers__AddUser__Container__Form">
          <img src="../../../static/images/icon_membre.png" alt="" />
          <form class="grid grid-cols-2 " @submit="handleSubmit">
            <div>
              <input type="text" placeholder="Nom*" v-model="name" required />
              <input
                type="text"
                placeholder="Prénom*"
                v-model="surname"
                required
              />
              <input type="text" placeholder="Email*" v-model="email" />
              <div id="birthday">
                <label>Date de naissance*</label>
                <input type="date" v-model="birthday" required />
              </div>
            </div>
            <div>
              <input
                type="text"
                placeholder="Poids (en kg)*"
                v-model="weight"
                required
              />
              <input
                type="text"
                placeholder="Taille (en cm)*"
                v-model="size"
                required
              />
              <select name="genre" id="genre" @change="handleChangeSelect">
                <option value="0">Homme</option>
                <option value="1">Femme</option>
              </select>
            </div>
            <button type="submit" class="col-span-3">Créer un membre</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      birthday: null,
      name: null,
      email: null,
      surname: null,
      gender: null,
      weight: null,
      size: null
    };
  },
  props: {
    show: { type: Function }
  },
  methods: {
    closeAddMember() {
      this.$store.commit("home/changeShowAddMember", false);
    },
    handleChangeSelect(e) {
      this.gender = e.target.value;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.$loading(true);
      const button = document.querySelector(
        "#ManageUsers__AddUser__Container__Form > form > button"
      );
      button.disabled = true;
      axios
        .post(
          "https://www.upulse.fr/ConnectDiagoInteropServiceHost/api/connect/diago/saveUserBackOffice",
          {
            centerId: {
              CenterName: localStorage.getItem("centerName"),
              StructureName: localStorage.getItem("structureName")
            },
            userInfo: {
              BirthDate: this.birthday,
              LastName: this.name,
              FirstName: this.surname,
              Email: this.email,
              CardioLevel: 1,
              MuscularLevel: 1,
              UserAccountType: 2,
              Gender: this.gender,
              Weight: this.weight,
              Height: this.size / 100
            }
          }
        )
        .then(resp => {
          let data = { ...resp.data };

          this.$store.state.users.allUsers.push(data);
          this.$store.commit("home/resetAllStatesHome");

          setTimeout(() => {
            this.$store.state.home.showAddMember = false;
            this.$store.state.home.MembersState = true;
          }, 100);

          this.$loading(false);
        })
        .catch(() => {
          this.$loading(false);
          button.disabled = false;
          alert("Erreur d'enregistrement");
        });
    }
  }
};
</script>
