<template>
  <div id="Board__Speetch" class="col-span-3 lg:col-span-2">
    <div id="Board__Speetch__ContainerLeft">
      <p id="Board__Speetch__ContainerLeft__Title">Communiquez</p>
      <p id="Board__Speetch__ContainerLeft__Description">
        Basé sur les données de vos propres utilisateurs. Diago vous offre du
        contenu précieux pour valoriser votre communauté sur vos réseaux
        sociaux.
      </p>
      <a href="/" @click="goToSpeetch">
        <span>ACCÉDER AUX CONTENUS</span>
        <img
          src="../../../static/images/icon-next-rose.png"
          alt="img arrow pink"
        />
      </a>
    </div>
    <div id="Board__Speetch__ContainerRight">
      <img
        src="../../../static/images/DIAGOPRO-yourbestpos.png"
        alt="img post"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToSpeetch(e) {
      e.preventDefault();
      this.$store.commit("home/resetAllStatesHome");
      this.$store.commit("home/changeValueStates", {
        name: "marketingState",
        value: true
      });
    }
  }
};
</script>
