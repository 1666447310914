<template>
  <div id="Login" class="grid grid-cols-1 lg:grid-cols-2">
    <div id="Login__Logo" class="flex justify-center items-center">
      <img src="../../static/images/logo_diagopro@2x.png" alt="Logo" />
    </div>
    <div id="Login__Form" class="flex justify-center items-center flex-col">
      <h1>
        Connectez-vous
        <br />à votre compte pro.
      </h1>
      <form
        class="flex justify-start items-start flex-col"
        @submit="handleSubmit"
      >
        <label>
          <input
            type="text"
            placeholder="Identifiant"
            required
            v-model="ident"
          />
        </label>
        <label>
          <input
            type="password"
            placeholder="Mot de passe"
            required
            v-model="pwd"
          />
        </label>
        <label>
          <input
            type="text"
            placeholder="Code centre"
            required
            v-model="center"
          />
        </label>
        <p v-if="error">Identifiant incorrect !</p>
        <button type="submit">CONNEXION</button>
      </form>
      <a href="/">Mot de passe oublié ?</a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      ident: "",
      pwd: "",
      center: "",
      error: ""
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      axios
        .post(
          "https://www.upulse.fr/ConnectDiagoInteropServiceHost/api/connect/diago/loginCoach",
          {
            Email: this.ident,
            Password: this.pwd,
            CenterCode: this.center
          }
        )
        .then(response => {
          localStorage.setItem(
            "structureName",
            response.data.centerId.StructureName
          );
          localStorage.setItem("centerName", response.data.centerId.CenterName);
          localStorage.setItem("coachGuid", response.data.coachGuid);
          localStorage.setItem(
            "manager",
            response.data.roles.includes("Manager")
          );
          this.$router.push("/");
        })
        .catch(() => {
          this.error = true;
          setTimeout(() => {
            this.error = false;
          }, 2000);
        });
    }
  }
};
</script>
