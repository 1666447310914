<template>
  <div id="Age">
    <h2>Âge physiologique</h2>
    <div id="Age__Bullet">
      <p>
        {{ returnAge }} <br />
        <span>ans</span>
      </p>
    </div>
    <div id="Age__Text">
      <div id="Age__Text__Title">
        <img
          src="../../../../static/images/check_rose@2x.png"
          alt="check_rose"
        />
        <p>
          Interprétation<br />
          du résultat
        </p>
      </div>
      <div id="Age__Text__Description">
        <div v-html="returnText"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    returnAge() {
      return this.bilan[this.$store.state.home.bilanSelected]
        ? this.bilan[this.$store.state.home.bilanSelected].physioAge
          ? this.bilan[this.$store.state.home.bilanSelected].physioAge
          : 0
        : 0;
    },
    returnText() {
      const bilanSelected = this.bilan[this.$store.state.home.bilanSelected];
      return bilanSelected
        ? bilanSelected.physioAge < 1 && bilanSelected.physioAge > -1
          ? this.texteNeutre
          : this.age > 1
          ? this.texteFort
          : this.texteFaible
        : "Aucun résultat";
    }
  },
  data() {
    return {
      age: this.bilan[this.$store.state.home.bilanSelected].physioAge,
      text: "",
      texteNeutre:
        "<p>L'âge physiologique correspond (+ou - 1 an) <br> " +
        "à l'âge réel : la condition physique de<br> " +
        "votre client rentre dans des standards<br>" +
        " de bien-être et de bonne santé.<br><br>" +
        "<strong>CONSEIL :</strong> axer une préconisation sur l'amélioration d'un des points forts </p>",
      texteFort:
        "<p>L'âge physiologique est inférieur à l'âge<br>" +
        "réel de la personne : Bonne nouvelle, la<br>" +
        "condition physique de votre client est<br>" +
        "au-dessus de la moyenne des gens de\n" +
        "sa population.<br><br>" +
        "<strong>CONSEIL :</strong> axer une préconisation sur la\n" +
        "diminution du point faible observé</p>",
      texteFaible:
        "<p>L'âge physiologique est supérieur à<br>" +
        "l'âge réel : la condition physique de<br>" +
        "votre client ne rentre pas dans des<br>" +
        "standards de bien-être et de bonne<br>" +
        "santé. Le client est considéré comme<br>" +
        "déconditionné<br><br>" +
        "<strong>CONSEIL :</strong> axer une préconisation sur la\n" +
        "diminution du point faible observé</p>"
    };
  },
  props: {
    bilan: { type: Array }
  }
};
</script>
