<template>
  <div id="Evols__Muscu" class="ContainerCharts__Evols">
    <div id="Evols__Muscu__Header">
      <h2>Musculaire</h2>
      <div id="Evols__Muscu__Header__Filter">
        <img
          src="../../../../../static/images/icon_filtre_bleu@2x.png"
          alt=""
        />
        <p>Filtrer par aptitude :</p>
        <select v-model="selectedType" @change="updateCharts">
          <option value="muscuScore">NOTE GLOBALE</option>
          <option value="nbPompesValeur">NOMBRE DE POMPES</option>
          <option value="detenteVerticaleValeur">DÉTENTE VERTICALE</option>
        </select>
        <p>par session :</p>
        <select v-model="selectedTime" @change="updateCharts">
          <option value="3">3 derniers bilans</option>
          <option value="6">6 derniers bilans</option>
          <option value="-1">Tous</option>
        </select>
      </div>
    </div>
    <div id="Evols__Muscu__Charts">
      <apexchart
        type="line"
        ref="activityChart"
        height="300"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
require("dayjs/locale/fr");
dayjs.locale("fr");
export default {
  name: "index",
  beforeMount() {
    if (this.bilan.length > 0) {
      for (let i = 0; i < 3; i++) {
        if (
          this.bilan[i] &&
          this.bilan[i].muscuScore &&
          this.bilan[i].muscuScore !== -1
        ) {
          this.series[0].data.push(this.bilan[i].muscuScore);
          this.chartOptions.xaxis.categories.push(
            dayjs(this.bilan[i].startDate).format("DD MMM YYYY")
          );
        }
      }
      this.series[0].data.reverse();
      this.chartOptions.xaxis.categories.reverse();
    }
  },
  data() {
    return {
      selectedType: "muscuScore",
      selectedTime: "3",
      series: [
        {
          name: "MUSCULAIRE",
          data: []
        }
      ],
      chartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: ["#fa8282"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        markers: {
          size: 5
        },
        legend: {
          show: true,
          horizontalAlign: "left",
          fontSize: "14px",
          fontWeight: 600,
          markers: {
            width: 15,
            height: 15,
            strokeColor: "#fff",
            radius: 12
          },
          itemMargin: {
            horizontal: 10,
            vertical: 15
          }
        }
      }
    };
  },
  methods: {
    updateCharts() {
      this.chartOptions.xaxis.categories = [];
      this.series[0].data = [];
      const stopI =
        this.selectedTime !== "-1"
          ? Number(this.selectedTime)
          : this.bilan.length;

      if (this.bilan.length > 0) {
        for (let i = 0; i < stopI; i++) {
          if (
            this.bilan[i] &&
            this.bilan[i][this.selectedType] &&
            this.bilan[i][this.selectedType] !== -1
          ) {
            this.series[0].data.push(this.bilan[i][this.selectedType]);
            this.chartOptions.xaxis.categories.push(
              dayjs(this.bilan[i].startDate).format("DD MMM YYYY")
            );
          }
        }

        this.$refs.activityChart.updateOptions({
          xaxis: {
            categories: this.chartOptions.xaxis.categories.reverse()
          }
        });
        this.$refs.activityChart.updateSeries(
          [
            {
              name: "MUSCULAIRE",
              data: this.series[0].data.reverse()
            }
          ],
          true,
          true
        );
      }
    }
  },
  props: {
    bilan: { type: Array }
  }
};
</script>
