<template>
  <div id="Financier">
    <MonthFinance :month="dataFinance[0]" />
    <ThisMonthGenerate :month="dataFinance[0]" />
    <EvoFinance :dataFinance="dataFinance" />
    <RepFinance :dataFinance="dataFinance" />
  </div>
</template>

<script>
import MonthFinance from "./MonthFinance";
import ThisMonthGenerate from "./ThisMonthGenerate";
import EvoFinance from "./EvoFinance";
import RepFinance from "./RepFinance";
export default {
  components: { MonthFinance, ThisMonthGenerate, EvoFinance, RepFinance },
  props: {
    dataFinance: { type: Array }
  }
};
</script>
