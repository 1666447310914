<template>
  <div id="Activity__Follow" class="Activity__Container">
    <h2>Suivi d'activité</h2>
    <div id="Activity__Follow__Filter">
      <img src="../../../../static/images/icon_filtre_bleu@2x.png" alt="" />
      <p>Filtrer par session :</p>
      <select v-model="selectedType" @change="updateCharts">
        <option value="countTraining">NOMBRE DE SÉANCES</option>
        <option value="daysActive">NOMBRE DE JOURS ACTIFS</option>
        <option value="calories">CALORIES BRÛLÉES</option>
        <option value="trainingDuration">TEMPS D'ENTRAÎNEMENT</option>
      </select>
      <p>par période :</p>
      <select v-model="selectedTime" @change="updateCharts">
        <option value="3">3 mois</option>
        <option value="6">6 mois</option>
        <option value="-1">Tout</option>
      </select>
    </div>
    <div id="Activity__Sollicitation__Charts">
      <apexchart
        type="line"
        ref="activityChart"
        height="300"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);
export default {
  beforeMount() {
    const currentMonth = dayjs();

    if (this.activities.length > 0) {
      this.addDuration();

      for (let i = 0; i < this.activities.length; i++) {
        const scopeDate = dayjs(currentMonth)
          .month(Number(this.activities[i].month) - 1)
          .year(this.activities[i].year);

        if (
          currentMonth.diff(scopeDate, "month") < 3 &&
          currentMonth.diff(scopeDate, "month") >= 0
        ) {
          this.series[0].data.push(
            this.activities[i].trainingDuration
              ? this.activities[i].trainingDuration
              : 0
          );
          this.chartOptions.xaxis.categories.push(
            dayjs(scopeDate)
              .format("MMM YYYY")
              .toUpperCase()
          );
        }
      }

      this.series[0].data.reverse();
      this.chartOptions.xaxis.categories.reverse();
    }
  },
  data() {
    return {
      selectedType: "trainingDuration",
      selectedTime: "3",
      labels: {
        formatter: function(value) {
          return dayjs
            .duration(0, "hours")
            .add(Number(value), "minutes")
            .format("H:m");
        }
      },
      series: [
        {
          name: "Session sélectionnée",
          data: []
        }
      ],
      chartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: ["#fa8282"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: {},
        markers: {
          size: 5
        },
        legend: {
          show: true,
          horizontalAlign: "left",
          fontSize: "14px",
          fontWeight: 600,
          markers: {
            width: 15,
            height: 15,
            strokeColor: "#fff",
            radius: 12
          },
          itemMargin: {
            horizontal: 10,
            vertical: 15
          }
        }
      }
    };
  },
  methods: {
    updateCharts() {
      const currentMonth = dayjs();
      this.chartOptions.xaxis.categories = [];
      this.series[0].data = [];

      if (this.activities.length > 0) {
        const stopDate =
          Number(this.selectedTime) !== -1 ? Number(this.selectedTime) : 1000;

        for (let i = 0; i < this.activities.length; i++) {
          const scopeDate = dayjs(currentMonth)
            .month(Number(this.activities[i].month) - 1)
            .year(this.activities[i].year);

          if (
            currentMonth.diff(scopeDate, "month") < stopDate &&
            currentMonth.diff(scopeDate, "month") >= 0
          ) {
            if (this.selectedType === "countTraining") {
              this.series[0].data.push(
                this.activities[i] ? this.returnTraining(this.activities[i]) : 0
              );
            } else {
              this.series[0].data.push(
                this.activities[i][this.selectedType]
                  ? this.activities[i][this.selectedType]
                  : 0
              );
            }
            this.chartOptions.xaxis.categories.push(
              dayjs(scopeDate)
                .format("MMM YYYY")
                .toUpperCase()
            );
          }
        }

        this.$refs.activityChart.updateOptions({
          xaxis: {
            categories: this.chartOptions.xaxis.categories.reverse()
          },
          yaxis: {
            labels: this.selectedType === "trainingDuration" ? this.labels : {}
          }
        });
        this.$refs.activityChart.updateSeries(
          [
            {
              name: "Session sélectionnée",
              data: this.series[0].data.reverse()
            }
          ],
          true,
          true
        );
      }
    },
    addDuration() {
      this.chartOptions.yaxis["labels"] = this.labels;
    },
    returnTraining(activity) {
      let circuits = activity.circuits ? Number(activity.circuits.nbCirc) : 0;
      let activites = activity.activites ? Number(activity.activites.nb) : 0;
      let coursCo = activity.coursCo ? Number(activity.coursCo.nb) : 0;
      let outdoor = activity.outdoor ? Number(activity.outdoor.nb) : 0;
      let videos = activity.videos ? Number(activity.videos.nb) : 0;
      let muscuCoache = activity.muscuCoache
        ? Number(activity.muscuCoache.nb)
        : 0;
      let cardioCoache = activity.cardioCoache
        ? Number(activity.cardioCoache.nb)
        : 0;

      return (
        circuits +
        activites +
        coursCo +
        outdoor +
        videos +
        muscuCoache +
        cardioCoache
      );
    }
  },
  props: {
    activities: { type: Array }
  }
};
</script>
