<template>
  <div id="Marketing">
    <div id="Marketing__Title">
      <p>Données marketing</p>
    </div>
    <MarketingDescription />
    <div id="Marketing__SubTitle">
      <p>Animez votre communauté !</p>
    </div>
    <MarketingTopPost :marketing="marketing" />
    <MarketingThematique :marketing="marketing" />
  </div>
</template>

<script>
import MarketingDescription from "./Description";
import MarketingTopPost from "./TopPosts";
import MarketingThematique from "./Thematique";
export default {
  components: { MarketingDescription, MarketingTopPost, MarketingThematique },
  props: {
    marketing: { type: Array }
  }
};
</script>
