import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/main.scss";
import "tailwindcss/dist/tailwind.min.css";
import "./plugins/loading";
import "./plugins/chart";
import "./plugins/social";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
