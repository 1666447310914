<template>
  <div id="Home" v-if="isLoaded">
    <MenuBar />
    <HeaderHome v-if="condHeaderHome" />
    <div id="Home__ContainerRight" v-if="isLoaded">
      <Board
        v-if="this.$store.state.home.boardState"
        :current="currentMonth"
        :marketing="marketing"
        :last="lastMonth"
      />
      <ManageUsers v-if="this.$store.state.home.MembersState" />
      <Profil v-if="this.$store.state.home.showProfil" />
      <Finance
        v-if="this.$store.state.home.financeState"
        :dataFinance="monthData"
      />
      <Marketing
        v-if="this.$store.state.home.marketingState"
        :marketing="marketing"
      />
      <Help v-if="this.$store.state.home.helpState" />
      <InWork v-if="this.$store.state.home.analytiqueState" />
    </div>
  </div>
</template>

<script>
import MenuBar from "../../components/MenuBar";
import HeaderHome from "../../components/HeaderHome";
import Board from "../../components/Board";
import ManageUsers from "../../components/ManageUsers";
import Profil from "../../components/Profil";
import Finance from "../../components/Finance";
import Help from "../../components/Help";
import InWork from "../../components/InWork";
import Marketing from "../../components/Marketing";
import {
  fetchFinance,
  fetchMarketing,
  fetchUsers
} from "../../utils/fnc/request";
export default {
  components: {
    MenuBar,
    HeaderHome,
    Board,
    ManageUsers,
    Profil,
    Marketing,
    Help,
    Finance,
    InWork
  },
  async beforeMount() {
    if (localStorage.getItem("structureName") === null) {
      return this.$router.push("login");
    } else {
      this.$loading(true);

      let users = [];
      let dataFinance = {};

      // Fetch all users.
      try {
        users = await fetchUsers();
        await this.$store.dispatch("users/actionSetAllUsers", users.data);
      } catch (error) {
        console.error("ERROR FETCH USERS", error);
      }

      // Fetch all finance.
      try {
        dataFinance = await fetchFinance();
        this.currentMonth = dataFinance.currentMonth;
        this.lastMonth = dataFinance.lastMonth;
        this.monthData = dataFinance.allMonth;
      } catch (error) {
        console.error("ERROR fetchFinance", error);
      }

      try {
        const marketing = await fetchMarketing(true, true);
        this.marketing = marketing.data;
      } catch (error) {
        console.error("ERROR fetchMarketing", error);
      }

      this.isLoaded = true;
      this.$loading(false);
    }
  },
  computed: {
    condHeaderHome() {
      return (
        !this.$store.state.home.showAddMember &&
        !this.$store.state.home.showEditMember &&
        !this.$store.state.home.showSearchMember
      );
    }
  },
  data() {
    return {
      lastMonth: {},
      currentMonth: {},
      monthData: [],
      marketing: [],
      isLoaded: false
    };
  }
};
</script>
