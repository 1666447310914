<template>
  <div id="Board__Finance" class="col-span-3 lg:col-span-1">
    <p id="Board__Finance__Title">Finances</p>
    <p id="Board__Finance__Slug">Crédit en votre faveur</p>
    <p id="Board__Finance__Sum">
      {{ calculPrice }}€
      <img src="../../../static/images/icon_credit@2x.png" alt="Logo Price" />
    </p>
    <a href="/" @click="goToFinance">
      <span>Voir plus</span>
      <img src="../../../static/images/icon-next-rose.png" alt="" id="" />
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      price: {
        1: 3,
        3: 16.6,
        6: 30
      }
    };
  },
  computed: {
    calculPrice() {
      let many = 0;

      if (this.current.diagoSubscribedUsers.length > 0) {
        many +=
          this.current.diagoSubscribedUsers.length *
          this.current.diagoSubscribedUsers[0].value;
      }

      if (this.current.diagoPackUsers.length > 0) {
        this.current.diagoPackUsers.forEach(p => {
          let typePack = p.packName.match(/\d+/);
          many += this.price[typePack];
        });
      }

      return many;
    }
  },
  methods: {
    goToFinance(e) {
      e.preventDefault();
      this.$store.commit("home/resetAllStatesHome");
      setTimeout(() => {
        this.$store.state.home.financeState = true;
      }, 100);
    }
  },
  props: {
    users: { type: Array },
    current: { type: Object }
  }
};
</script>
