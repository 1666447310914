<template>
  <div id="RepCardio" class="ContainerCharts__Repartitions">
    <header>
      <h2>Répartition des sollicitations musculaires</h2>
      <div id="RepCardio__Filter" class="filter">
        <img
          src="../../../../../static/images/icon_filtre_bleu@2x.png"
          alt=""
        />
        <p>Filtrer par période :</p>
        <select v-model="selectedTime" @change="updateCharts">
          <option value="3">3 mois</option>
          <option value="6">6 mois</option>
          <option value="-1">Tout</option>
        </select>
      </div>
    </header>
    <div id="RepCardio__Charts">
      <apexchart
        type="bar"
        ref="activityChart"
        height="500"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);
export default {
  beforeMount() {
    const currentMonth = dayjs();

    if (this.activities.length > 0) {
      for (let i = 0; i < this.activities.length; i++) {
        const scopeDate = dayjs(currentMonth)
          .month(Number(this.activities[i].month) - 1)
          .year(this.activities[i].year);

        if (
          currentMonth.diff(scopeDate, "month") <= 2 &&
          currentMonth.diff(scopeDate, "month") >= 0
        ) {
          const abdos = this.activities[i].exercicesAbdos
            ? Number(this.activities[i].exercicesAbdos)
            : 0;
          const adducteurs = this.activities[i].exercicesAdduc
            ? Number(this.activities[i].exercicesAdduc)
            : 0;
          const biceps = this.activities[i].exercicesBiceps
            ? Number(this.activities[i].exercicesBiceps)
            : 0;
          const dorsaux = this.activities[i].exercicesDos
            ? Number(this.activities[i].exercicesDos)
            : 0;
          const epaules = this.activities[i].exercicesEpaules
            ? Number(this.activities[i].exercicesEpaules)
            : 0;
          const fessiers = this.activities[i].exercicesGlute
            ? Number(this.activities[i].exercicesGlute)
            : 0;
          const ischios = this.activities[i].exercicesIschio
            ? Number(this.activities[i].exercicesIschio)
            : 0;
          const lombaires = this.activities[i].exercicesLombs
            ? Number(this.activities[i].exercicesLombs)
            : 0;
          const mollets = this.activities[i].exercicesMollets
            ? Number(this.activities[i].exercicesMollets)
            : 0;
          const pectauraux = this.activities[i].exercicesPecs
            ? Number(this.activities[i].exercicesPecs)
            : 0;
          const quadriceps = this.activities[i].exercicesQuadri
            ? Number(this.activities[i].exercicesQuadri)
            : 0;
          const trapeze = this.activities[i].exercicesTraps
            ? Number(this.activities[i].exercicesTraps)
            : 0;
          const triceps = this.activities[i].exercicesTriceps
            ? Number(this.activities[i].exercicesTriceps)
            : 0;
          //TODO Ajout des avant bras
          // const avantbras = this.activities[i].exercicesTriceps
          //   ? Number(this.activities[i].exercicesTriceps)
          //   : 0;

          //TODO Ajout des avant bras
          const total =
            abdos +
            adducteurs +
            biceps +
            dorsaux +
            epaules +
            fessiers +
            ischios +
            lombaires +
            mollets +
            pectauraux +
            quadriceps +
            trapeze +
            triceps;

          this.series[0].data.push(this.percentage(abdos, total));
          this.series[1].data.push(this.percentage(adducteurs, total));
          this.series[2].data.push(this.percentage(biceps, total));
          this.series[3].data.push(this.percentage(dorsaux, total));
          this.series[4].data.push(this.percentage(epaules, total));
          this.series[5].data.push(this.percentage(fessiers, total));
          this.series[6].data.push(this.percentage(ischios, total));
          this.series[7].data.push(this.percentage(lombaires, total));
          this.series[8].data.push(this.percentage(mollets, total));
          this.series[9].data.push(this.percentage(pectauraux, total));
          this.series[10].data.push(this.percentage(quadriceps, total));
          this.series[11].data.push(this.percentage(trapeze, total));
          this.series[12].data.push(this.percentage(triceps, total));
          //TODO Ajout des avant bras
          //this.series[13].data.push(this.percentage(avantbras, total));

          this.chartOptions.xaxis.categories.push(
            dayjs(scopeDate)
              .format("MMM YYYY")
              .toUpperCase()
          );
        }
      }

      //TODO Ajout des avant bras mettre à le i à 14
      for (let i = 0; i < 13; i++) {
        this.series[i].data.reverse();
      }
      this.chartOptions.xaxis.categories.reverse();
    }
  },
  data() {
    return {
      selectedTime: "3",
      series: [
        {
          name: "Abdos",
          data: []
        },
        {
          name: "Adducteurs",
          data: []
        },
        {
          name: "Biceps",
          data: []
        },
        {
          name: "Dorsaux",
          data: []
        },
        {
          name: "Epaules",
          data: []
        },
        {
          name: "Fessiers",
          data: []
        },
        {
          name: "Ischios",
          data: []
        },
        {
          name: "Lombaires",
          data: []
        },
        {
          name: "Mollets",
          data: []
        },
        {
          name: "Pectoraux",
          data: []
        },
        {
          name: "Quadriceps",
          data: []
        },
        {
          name: "Trapèze",
          data: []
        },
        {
          name: "Triceps",
          data: []
        }
        //TODO Ajout des avant bras
        // {
        //   name: "Avant-Bras",
        //   data: []
        // }
      ],
      chartOptions: {
        chart: {
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "75%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: [
          "#FA8282",
          "#B44C4C",
          "#FF925C",
          "#D96126",
          "#934087",
          "#D150BF",
          "#C38EDB",
          "#7EABBF",
          "#2A7495",
          "#333745",
          "#0DA280",
          "#45694D",
          "#FFC75C"
          //TODO Ajout des avant bras
          //"#BA8318"
        ],
        xaxis: {
          categories: [],
          axisBorder: {
            show: true,
            color: "#333745",
            height: 1,
            width: "100%"
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#333745",
            height: 6
          }
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return `${value}%`;
            }
          },
          max: 100,
          tickAmount: 5
        },
        markers: {
          size: 5
        },
        legend: {
          show: true,
          horizontalAlign: "center",
          fontFamily: "Montserrat, Arial",
          fontSize: "13px",
          offsetY: 30,
          fontWeight: 400,
          labels: {
            colors: "#333745FF",
            useSeriesColors: false
          },
          markers: {
            width: 25,
            height: 25,
            radius: 50
          },
          itemMargin: {
            horizontal: 10,
            vertical: 30
          }
        }
      }
    };
  },
  methods: {
    percentage(partialValue, totalValue) {
      return Math.round((100 * partialValue) / totalValue);
    },
    updateCharts() {
      const currentMonth = dayjs();
      this.chartOptions.xaxis.categories = [];

      //TODO Ajout des avant bras mettre à le i à 14
      for (let i = 0; i < 13; i++) {
        this.series[i].data = [];
      }

      const stopDate =
        Number(this.selectedTime) !== -1 ? Number(this.selectedTime) : 1000;

      if (this.activities.length > 0) {
        for (let i = 0; i < this.activities.length; i++) {
          const scopeDate = dayjs(currentMonth)
            .month(Number(this.activities[i].month) - 1)
            .year(this.activities[i].year);

          if (
            currentMonth.diff(scopeDate, "month") <= stopDate &&
            currentMonth.diff(scopeDate, "month") >= 0
          ) {
            const abdos = this.activities[i].exercicesAbdos
              ? Number(this.activities[i].exercicesAbdos)
              : 0;
            const adducteurs = this.activities[i].exercicesAdduc
              ? Number(this.activities[i].exercicesAdduc)
              : 0;
            const biceps = this.activities[i].exercicesBiceps
              ? Number(this.activities[i].exercicesBiceps)
              : 0;
            const dorsaux = this.activities[i].exercicesDos
              ? Number(this.activities[i].exercicesDos)
              : 0;
            const epaules = this.activities[i].exercicesEpaules
              ? Number(this.activities[i].exercicesEpaules)
              : 0;
            const fessiers = this.activities[i].exercicesGlute
              ? Number(this.activities[i].exercicesGlute)
              : 0;
            const ischios = this.activities[i].exercicesIschio
              ? Number(this.activities[i].exercicesIschio)
              : 0;
            const lombaires = this.activities[i].exercicesLombs
              ? Number(this.activities[i].exercicesLombs)
              : 0;
            const mollets = this.activities[i].exercicesMollets
              ? Number(this.activities[i].exercicesMollets)
              : 0;
            const pectauraux = this.activities[i].exercicesPecs
              ? Number(this.activities[i].exercicesPecs)
              : 0;
            const quadriceps = this.activities[i].exercicesQuadri
              ? Number(this.activities[i].exercicesQuadri)
              : 0;
            const trapeze = this.activities[i].exercicesTraps
              ? Number(this.activities[i].exercicesTraps)
              : 0;
            const triceps = this.activities[i].exercicesTriceps
              ? Number(this.activities[i].exercicesTriceps)
              : 0;
            //TODO Ajout des avant bras
            // const avantbras = this.activities[i].exercicesTriceps
            //   ? Number(this.activities[i].exercicesTriceps)
            //   : 0;

            //TODO Ajout des avant bras +avantbras
            const total =
              abdos +
              adducteurs +
              biceps +
              dorsaux +
              epaules +
              fessiers +
              ischios +
              lombaires +
              mollets +
              pectauraux +
              quadriceps +
              trapeze +
              triceps;

            this.series[0].data.push(this.percentage(abdos, total));
            this.series[1].data.push(this.percentage(adducteurs, total));
            this.series[2].data.push(this.percentage(biceps, total));
            this.series[3].data.push(this.percentage(dorsaux, total));
            this.series[4].data.push(this.percentage(epaules, total));
            this.series[5].data.push(this.percentage(fessiers, total));
            this.series[6].data.push(this.percentage(ischios, total));
            this.series[7].data.push(this.percentage(lombaires, total));
            this.series[8].data.push(this.percentage(mollets, total));
            this.series[9].data.push(this.percentage(pectauraux, total));
            this.series[10].data.push(this.percentage(quadriceps, total));
            this.series[11].data.push(this.percentage(trapeze, total));
            this.series[12].data.push(this.percentage(triceps, total));
            //TODO Ajout des avant bras
            //this.series[13].data.push(this.percentage(avantbras, total));

            this.chartOptions.xaxis.categories.push(
              dayjs(scopeDate)
                .format("MMM YYYY")
                .toUpperCase()
            );
          }
        }

        this.$refs.activityChart.updateOptions({
          xaxis: {
            categories: this.chartOptions.xaxis.categories.reverse()
          }
        });
        this.$refs.activityChart.updateSeries(
          [
            {
              name: "Abdos",
              data: this.series[0].data.reverse()
            },
            {
              name: "Adducteurs",
              data: this.series[1].data.reverse()
            },
            {
              name: "Biceps",
              data: this.series[2].data.reverse()
            },
            {
              name: "Dorsaux",
              data: this.series[3].data.reverse()
            },
            {
              name: "Epaules",
              data: this.series[4].data.reverse()
            },
            {
              name: "Fessiers",
              data: this.series[5].data.reverse()
            },
            {
              name: "Ischios",
              data: this.series[6].data.reverse()
            },
            {
              name: "Lombaires",
              data: this.series[7].data.reverse()
            },
            {
              name: "Mollets",
              data: this.series[8].data.reverse()
            },
            {
              name: "Pectoraux",
              data: this.series[9].data.reverse()
            },
            {
              name: "Quadriceps",
              data: this.series[10].data.reverse()
            },
            {
              name: "Trapèze",
              data: this.series[11].data.reverse()
            },
            {
              name: "Triceps",
              data: this.series[12].data.reverse()
            }
            //TODO Ajout des avant bras
            // {
            //   name: "Avant-Bras",
            //   data: this.series[13].data.reverse()
            // }
          ],
          true,
          true
        );
      }
    }
  },
  props: {
    activities: { type: Array }
  }
};
</script>
