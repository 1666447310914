import Vue from "vue";
import Vuex from "vuex";
import HomeStore from "./home-store";
import UsersStore from "./users.store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    home: HomeStore,
    users: UsersStore
  }
});
