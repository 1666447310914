<template>
  <div id="EvoFinance">
    <div id="EvoFinance__Header">
      <h2>Evolution financière</h2>
      <div id="EvoFinance__Header__Filter">
        <p>
          <img
            src="../../../static/images/icon_filtre_bleu.png"
            alt="icon_filtre"
          />
          Filtrer par période
        </p>

        <select @change="updateChart">
          <option value="3">3 mois</option>
          <option value="6">6 mois</option>
          <option value="1">1 ans</option>
        </select>
      </div>
    </div>
    <apexchart
      type="bar"
      height="350"
      ref="activityChart"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  mounted() {
    this.updateChart();
  },
  data() {
    return {
      PBO: 0,
      Diago: 0,
      3: 0,
      6: 0,
      1: 0,
      price: {
        1: 3,
        3: 16.6,
        6: 30
      },
      series: [
        {
          name: "Débit en cours",
          data: []
        },
        {
          name: "Crédit en votre faveur",
          data: []
        }
      ],
      chartOptions: {
        colors: ["#d36b6b", "#fa8282"],
        chart: {
          type: "bar",
          toolbar: {
            show: false
          }
        },
        legend: {
          show: true,
          horizontalAlign: "left",
          fontSize: "14px",
          fontWeight: 600,
          markers: {
            width: 15,
            height: 15,
            strokeColor: "#fff",
            radius: 12
          },
          itemMargin: {
            horizontal: 10,
            vertical: 15
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            dataLabels: {
              position: "top" // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val + "€";
          },
          offsetY: -20,
          style: {
            fontSize: "14px",
            colors: ["#304758"],
            fontWeight: 600
          }
        },

        xaxis: {
          categories: [],
          position: "bottom"
        },
        yaxis: {
          labels: {
            show: true,
            formatter: function(val) {
              return val + "€";
            }
          }
        }
      }
    };
  },
  methods: {
    updateChartsAction(dataCat, dataSeries1, dataSeries2) {
      this.$refs.activityChart.updateOptions({
        xaxis: {
          categories: dataCat
        }
      });
      this.$refs.activityChart.updateSeries(
        [
          {
            data: dataSeries1
          },
          {
            data: dataSeries2
          }
        ],
        true,
        true
      );
    },
    updateChart() {
      let select = document.querySelector(
        "#EvoFinance__Header__Filter > select"
      ).value;
      const currentSelect = [];

      this.series[0].data = [];
      this.series[1].data = [];
      this.chartOptions.xaxis.categories = [];

      if (select === "1") {
        select = this.dataFinance.length - 1;
      }

      for (let i = 0; i < select; i++) {
        currentSelect.push(this.dataFinance[i]);
      }

      currentSelect.forEach(el => {
        let debit = 0;
        let credit = 0;
        const dateObject = new Date(el.date);
        const Year = dateObject.getFullYear();
        let formatter = new Intl.DateTimeFormat("fr", { month: "long" });
        let Month = formatter.format(dateObject);

        if (el.diagoSubscribedUsers.length > 0) {
          credit += el.diagoSubscribedUsers.length * 3;
        }

        if (el.diagoCenterOfferUsers.length > 0) {
          debit += el.diagoCenterOfferUsers.length * 5.2;
        }

        if (el.diagoPackUsers.length > 0) {
          el.diagoPackUsers.forEach(p => {
            let typePack = p.packName.match(/\d+/);
            credit += this.price[typePack];
          });
        }

        this.series[0].data.unshift(debit.toFixed(2));
        this.series[1].data.unshift(credit.toFixed(2));
        this.chartOptions.xaxis.categories.unshift(`${Month}-${Year}`);

        this.updateChartsAction(
          this.chartOptions.xaxis.categories,
          this.series[0].data,
          this.series[1].data
        );
      });
    }
  },
  props: {
    dataFinance: { type: Array }
  }
};
</script>
