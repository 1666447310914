const Home = {
  namespaced: true,
  state: () => ({
    boardState: true,
    MembersState: false,
    financeState: false,
    analytiqueState: false,
    marketingState: false,
    helpState: false,
    showAddMember: false,
    showSearchMember: false,
    showProfil: false,
    showDeleteUsers: false,
    showEditMember: false,
    showActivitiesProfil: true,
    showBilanProfil: true,

    /*-- Store data -- */
    textSearch: "",
    allUsers: [],
    profilSelected: {},
    bilanSelected: 0,
    allActivities: []
  }),
  mutations: {
    resetAllStatesHome(state, isSearch = false) {
      state.boardState = false;
      state.MembersState = false;
      state.financeState = false;
      state.analytiqueState = false;
      state.marketingState = false;
      state.helpState = false;
      state.showSearchMember = false;
      state.showProfil = false;
      state.showEditMember = false;
      state.showDeleteUsers = false;
      state.showActivitiesProfil = true;
      state.showBilanProfil = false;

      if (!isSearch) {
        state.textSearch = "";
      }
    },
    changeValueStates(state, data) {
      state[data.name] = data.value;
    },
    changeShowAddMember(state, data) {
      if (data === true) {
        state.showAddMember = true;
        state.MembersState = true;
      } else {
        state.showAddMember = data;
      }
    },
    changeShowEditMember(state, data) {
      if (data === true) {
        state.showEditMember = true;
        state.showProfil = true;
      } else {
        state.showEditMember = data;
      }
    },
    changeShowSearchMember(state, data) {
      if (data === true) {
        state.showSearchMember = true;
        state.MembersState = true;
      } else {
        state.showSearchMember = data;
      }
    },
    showPageProfil(state) {
      state.MembersState = false;
      state.showProfil = true;
    }
  }
};

export default Home;
