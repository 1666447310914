<template>
  <div id="Marketing__Description">
    <div id="Marketing__Description__Container" class="Marketing__Container">
      <div id="Marketing__Description__Container__Text">
        <h2 class="Marketing__Container__Title">
          Publiez des contenus pertinents <br />
          sur vos réseaux sociaux !
        </h2>
        <div id="Marketing__Description__Container__Text__Description">
          Le marketing par la preuve sociale est un levier fort pour augmenter
          <br />
          l'adhésion auprès de votre audience ou votre communauté.
          <br />
          <br />
          En effet, communiquer des données chiffrées sur des sujets qui <br />
          intéressent votre communauté peuvent aider ceux-ci à une prise de
          <br />
          décision (abonnement, achat d'un pack...)
          <br />
          <br />
          C'est pourquoi Diago vous offre du contenu précieux reposant sur des
          <br />
          données et des chiffres clés impactants, basés sur l'activité de vos
          <br />
          membres.
        </div>
      </div>
      <div id="Marketing__Description__Container__Image">
        <img src="../../../static/images/DIAGOPRO-yourbestpos.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index"
};
</script>
