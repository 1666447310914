<template>
  <div
    id="DirectAccess"
    class="col-span-3 lg:col-span-1"
    @click="openAddMember"
  >
    <p>Accès direct</p>
    <a href="/" @click="openAddMember">
      <img
        src="../../../static/images/icon_membre@2x.png"
        alt="Logo Members"
        style="height: 110px"
      />
      <p>Ajouter un membre</p>
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    openAddMember(e) {
      e.preventDefault();
      this.$store.commit("home/resetAllStatesHome");
      this.$store.commit("home/changeShowAddMember", true);
    }
  }
};
</script>
