<template>
  <div id="Activity__Sollicitation" class="Activity__Container">
    <h2>Suivi par type de sollicitations</h2>
    <div id="Activity__Sollicitation__Filter">
      <img src="../../../../static/images/icon_filtre_bleu@2x.png" alt="" />
      <p>Filtrer par aptitude :</p>
      <select v-model="selectedType" @change="updateCharts">
        <option value="cardioScore">CARDIO</option>
        <option value="muscuScore">MUSCULAIRE</option>
        <option value="balanceScore">ÉQUILIBRE</option>
        <option value="stressScore">PROFIL NERVEUX</option>
        <option value="flexScore">SOUPLESSE</option>
      </select>
      <p>par session :</p>
      <select v-model="selectedTime" @change="updateCharts">
        <option value="3">3 mois</option>
        <option value="6">6 mois</option>
        <option value="-1">Tout</option>
      </select>
    </div>
    <div id="Activity__Sollicitation__Charts">
      <apexchart
        type="line"
        ref="activityChart"
        height="500"
        :options="chartOptions"
        :series="series"
      />
      <div id="Activity__Sollicitation__Charts__OtherLegends">
        <div class="Activity__Sollicitation__Charts__OtherLegends--Legends">
          <div
            class="Activity__Sollicitation__Charts__OtherLegends--Legends__Color"
          ></div>
          <p>
            Zone 0 à 100 = <br />
            Activité insuffisante
          </p>
        </div>
        <div class="Activity__Sollicitation__Charts__OtherLegends--Legends">
          <div
            class="Activity__Sollicitation__Charts__OtherLegends--Legends__Color"
          ></div>
          <p>
            Zone 100 à 199 = <br />
            Activité efficace
          </p>
        </div>
        <div class="Activity__Sollicitation__Charts__OtherLegends--Legends">
          <div
            class="Activity__Sollicitation__Charts__OtherLegends--Legends__Color"
          ></div>
          <p>
            Zone 199 à 400 = <br />
            Optimisation des résultats
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  beforeMount() {
    const currentMonth = dayjs();

    if (this.activities.length > 0) {
      for (let i = 0; i < this.activities.length; i++) {
        const scopeDate = dayjs(currentMonth)
          .month(Number(this.activities[i].month) - 1)
          .year(this.activities[i].year);

        if (
          currentMonth.diff(scopeDate, "month") <= 2 &&
          currentMonth.diff(scopeDate, "month") >= 0
        ) {
          this.series[0].data.push(
            this.activities[i].score ? this.activities[i].score : 0
          );
          this.series[1].data.push(
            this.activities[i].cardioScore ? this.activities[i].cardioScore : 0
          );
          this.chartOptions.xaxis.categories.push(
            dayjs(scopeDate)
              .format("MMM YYYY")
              .toUpperCase()
          );
        }
      }

      this.series[0].data.reverse();
      this.series[1].data.reverse();
      this.chartOptions.xaxis.categories.reverse();
    }
  },
  data() {
    return {
      selectedType: "cardioScore",
      selectedTime: "3",
      series: [
        {
          name: "Activité globale",
          data: []
        },
        {
          name: "Aptitude sélectionnée",
          data: []
        }
      ],
      chartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: ["#333745", "#fa8282"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          dashArray: [4, 0]
        },
        grid: {
          row: {
            colors: ["#F7E4E4", "#F7E4E4", "#FFECE3", "#E2EDF1"], // takes an array which will be repeated on columns
            opacity: 1
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: [
          {
            min: 0,
            tickAmount: 4,
            max: 400
          }
        ],
        markers: {
          size: 5
        },
        legend: {
          show: true,
          horizontalAlign: "left",
          fontFamily: "Montserrat, Arial",
          fontSize: "13px",
          offsetY: 30,
          fontWeight: 400,
          labels: {
            colors: "#333745FF",
            useSeriesColors: false
          },
          markers: {
            width: 25,
            height: 25,
            radius: 50
          },
          itemMargin: {
            horizontal: 10,
            vertical: 30
          }
        }
      }
    };
  },
  methods: {
    updateCharts() {
      // Reset arrays.
      this.chartOptions.xaxis.categories = [];
      this.series[0].data = [];
      this.series[1].data = [];

      // Get current date.
      const currentMonth = dayjs();

      if (this.activities.length > 0) {
        for (let i = 0; i < this.activities.length; i++) {
          // Get instance date.
          const scopeDate = dayjs(currentMonth)
            .month(Number(this.activities[i].month) - 1)
            .year(this.activities[i].year);

          const stopDate =
            Number(this.selectedTime) !== -1
              ? Number(this.selectedTime) - 1
              : 1000;

          if (
            this.activities[i] &&
            currentMonth.diff(scopeDate, "month") <= stopDate &&
            currentMonth.diff(scopeDate, "month") >= 0
          ) {
            this.series[0].data.push(
              this.activities[i].score ? this.activities[i].score : 0
            );
            this.series[1].data.push(
              this.activities[i][this.selectedType]
                ? this.activities[i][this.selectedType]
                : 0
            );
            this.chartOptions.xaxis.categories.push(
              dayjs(scopeDate)
                .format("MMM YYYY")
                .toUpperCase()
            );
          }
        }

        this.$refs.activityChart.updateOptions({
          xaxis: {
            categories: this.chartOptions.xaxis.categories.reverse()
          }
        });
        this.$refs.activityChart.updateSeries(
          [
            {
              name: "Activité globale",
              data: this.series[0].data.reverse()
            },
            {
              name: "Aptitude sélectionnée",
              data: this.series[1].data.reverse()
            }
          ],
          true,
          true
        );
      }
    }
  },
  props: {
    activities: { type: Array }
  }
};
</script>
