<template>
  <div id="DeleteUser">
    <div id="DeleteUser__Container">
      <div id="DeleteUser__Container__Text">
        <img src="../../../static/images/icon_membre.png" alt="ico memebre" />
        <p>
          Etes-vous sûr de vouloir supprimer
          <br />
          un ou plusieurs membres sélectionné(s) ?
        </p>
      </div>
      <div id="DeleteUser__Container__Button">
        <button @click="closeModal">Annuler</button>
        <button @click="handleSubmit">Supprimer</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  methods: {
    closeModal() {
      this.$store.state.home.showDeleteUsers = false;
    },
    handleSubmit() {
      if (this.members.length > 0) {
        this.members.forEach(async el => {
          try {
            await axios.post(
              "https://www.upulse.fr/ConnectDiagoInteropServiceHost/api/connect/diago/userSuppression",
              {
                center: {
                  CenterName: localStorage.getItem("centerName"),
                  StructureName: localStorage.getItem("structureName")
                },
                userId: el.user.Id,
                coachGuid: localStorage.getItem("coachGuid")
              }
            );

            this.users.forEach((us, index) => {
              if (us === el) {
                this.users.splice(index, 1);
              }
            });

            this.$store.state.users.allUsers.forEach((user, index) => {
              if (user === el) {
                this.$store.state.users.allUsers.splice(index, 1);
              }
            });

            this.unselect();
            this.$store.state.home.MembersState = false;
            this.$store.commit("home/resetAllStatesHome");
            this.$store.state.home.showDeleteUsers = false;

            setTimeout(() => {
              this.$store.state.home.MembersState = true;
            }, 100);
          } catch (e) {
            console.error(e);
          }
        });
      }
    }
  },
  props: {
    members: { type: Array },
    users: { type: Array },
    unselect: { type: Function }
  }
};
</script>
