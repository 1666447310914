import axios from "axios";
import dayjs from "dayjs";

export const fetchUsers = () =>
  axios.post(
    "https://www.upulse.fr/ConnectDiagoInteropServiceHost/api/connect/diago/userList/@",
    {
      CenterName: localStorage.getItem("centerName"),
      StructureName: localStorage.getItem("structureName")
    }
  );

export const fetchFinance = async () => {
  const allRequest = [];
  let data = {};

  const centerId = {
    CenterName: localStorage.getItem("centerName"),
    StructureName: localStorage.getItem("structureName")
  };

  const url =
    "https://www.upulse.fr/ConnectDiagoInteropServiceHost/api/connect/diago/financier";

  for (let i = 0; i < 13; i++) {
    const month = axios.post(url, {
      centerId,
      month: formatMonthAndYear(i).month,
      year: formatMonthAndYear(i).year
    });
    allRequest.push(month);
  }

  await axios.all(allRequest).then(
    axios.spread(async (...responses) => {
      const month0 = responses[0].data;
      const month1 = responses[1].data;

      const arrayMonthData = formatArrayAllMonth(responses);

      data = {
        currentMonth: month0,
        lastMonth: month1,
        allMonth: arrayMonthData
      };
    })
  );
  return data;
};

export const fetchMarketing = (current, month) =>
  axios.post(
    "https://www.upulse.fr/ConnectDiagoInteropServiceHost/api/connect/diago/marketingStatistics",
    {
      center: {
        CenterName: localStorage.getItem("centerName"),
        StructureName: localStorage.getItem("structureName")
      },
      filter: {
        current,
        month
      }
    }
  );

const formatArrayAllMonth = response => {
  const arrayMonth = [];

  for (let i = 0; i < response.length; i++) {
    arrayMonth.push({
      date: dayjs()
        .subtract(i, "month")
        .toDate(),
      ...response[i].data
    });
  }

  return arrayMonth;
};

const formatMonthAndYear = subtract => {
  return {
    month:
      dayjs()
        .subtract(subtract, "month")
        .month() + 1,
    year: dayjs()
      .subtract(subtract, "month")
      .year()
  };
};
