<template>
  <div id="RepActivity" class="ContainerCharts__Repartitions">
    <header>
      <h2>Répartition des activités</h2>
      <div id="RepActivity__Filter" class="filter">
        <img
          src="../../../../../static/images/icon_filtre_bleu@2x.png"
          alt=""
        />
        <p>Filtrer par période :</p>
        <select v-model="selectedTime" @change="updateCharts">
          <option value="3">3 mois</option>
          <option value="6">6 mois</option>
          <option value="-1">Tout</option>
        </select>
      </div>
    </header>
    <div id="RepActivity__Charts">
      <apexchart
        type="bar"
        ref="activityChart"
        height="400"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);
export default {
  beforeMount() {
    const currentMonth = dayjs();

    if (this.activities.length > 0) {
      for (let i = 0; i < this.activities.length; i++) {
        const scopeDate = dayjs(currentMonth)
          .month(Number(this.activities[i].month) - 1)
          .year(this.activities[i].year);

        if (
          currentMonth.diff(scopeDate, "month") <= 2 &&
          currentMonth.diff(scopeDate, "month") >= 0
        ) {
          this.series[0].data.push(
            this.activities[i].muscuCoache
              ? this.activities[i].muscuCoache.nb
              : 0
          );
          this.series[1].data.push(
            this.activities[i].cardioCoache
              ? this.activities[i].cardioCoache.nb
              : 0
          );
          this.series[2].data.push(
            this.activities[i].circuits ? this.activities[i].circuits.nbCirc : 0
          );
          this.series[3].data.push(
            this.activities[i].coursCo ? this.activities[i].coursCo.nb : 0
          );
          this.series[4].data.push(
            this.activities[i].activites ? this.activities[i].activites.nb : 0
          );
          this.series[5].data.push(
            this.activities[i].videos ? this.activities[i].videos.nb : 0
          );
          this.series[6].data.push(
            this.activities[i].outdoor ? this.activities[i].outdoor.nb : 0
          );
          this.chartOptions.xaxis.categories.push(
            dayjs(scopeDate)
              .format("MMM YYYY")
              .toUpperCase()
          );
        }
      }

      for (let i = 0; i < 7; i++) {
        this.series[i].data.reverse();
      }
      this.chartOptions.xaxis.categories.reverse();
    }
  },
  data() {
    return {
      selectedTime: "3",
      labels: {
        formatter: function(value) {
          return dayjs
            .duration(0, "hours")
            .add(Number(value), "minutes")
            .format("H:m");
        }
      },
      series: [
        {
          name: "Séance muscu coachée",
          data: []
        },
        {
          name: "Séance cardio coachée",
          data: []
        },
        {
          name: "Circuit Fonctionnal Training",
          data: []
        },
        {
          name: "Cours collectifs",
          data: []
        },
        {
          name: "Activités libres",
          data: []
        },
        {
          name: "Vidéos",
          data: []
        },
        {
          name: "Outdoor",
          data: []
        }
      ],
      chartOptions: {
        chart: {
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: [
          "#FA8282",
          "#B44C4C",
          "#FF925C",
          "#D96126",
          "#934087",
          "#7EABBF",
          "#2A7495"
        ],
        xaxis: {
          categories: [],
          axisBorder: {
            show: true,
            color: "#333745",
            height: 1,
            width: "100%"
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#333745",
            height: 6
          }
        },
        markers: {
          size: 5
        },
        legend: {
          show: true,
          horizontalAlign: "center",
          fontFamily: "Montserrat, Arial",
          fontSize: "13px",
          offsetY: 30,
          fontWeight: 400,
          labels: {
            colors: "#333745FF",
            useSeriesColors: false
          },
          markers: {
            width: 25,
            height: 25,
            radius: 50
          },
          itemMargin: {
            horizontal: 10,
            vertical: 30
          }
        }
      }
    };
  },
  methods: {
    updateCharts() {
      const currentMonth = dayjs();
      this.chartOptions.xaxis.categories = [];

      for (let i = 0; i < 7; i++) {
        this.series[i].data = [];
      }

      const stopDate =
        Number(this.selectedTime) !== -1 ? Number(this.selectedTime) : 1000;

      if (this.activities.length > 0) {
        for (let i = 0; i < this.activities.length; i++) {
          const scopeDate = dayjs(currentMonth)
            .month(Number(this.activities[i].month) - 1)
            .year(this.activities[i].year);

          if (
            currentMonth.diff(scopeDate, "month") <= stopDate &&
            currentMonth.diff(scopeDate, "month") >= 0
          ) {
            this.series[0].data.push(
              this.activities[i].muscuCoache
                ? this.activities[i].muscuCoache.nb
                : 0
            );
            this.series[1].data.push(
              this.activities[i].cardioCoache
                ? this.activities[i].cardioCoache.nb
                : 0
            );
            this.series[2].data.push(
              this.activities[i].circuits
                ? this.activities[i].circuits.nbCirc
                : 0
            );
            this.series[3].data.push(
              this.activities[i].coursCo ? this.activities[i].coursCo.nb : 0
            );
            this.series[4].data.push(
              this.activities[i].activites ? this.activities[i].activites.nb : 0
            );
            this.series[5].data.push(
              this.activities[i].videos ? this.activities[i].videos.nb : 0
            );
            this.series[6].data.push(
              this.activities[i].outdoor ? this.activities[i].outdoor.nb : 0
            );
            this.chartOptions.xaxis.categories.push(
              dayjs(scopeDate)
                .format("MMM YYYY")
                .toUpperCase()
            );
          }
        }

        this.$refs.activityChart.updateOptions({
          xaxis: {
            categories: this.chartOptions.xaxis.categories.reverse()
          }
        });
        this.$refs.activityChart.updateSeries(
          [
            {
              name: "Séance muscu coachée",
              data: this.series[0].data.reverse()
            },
            {
              name: "Séance cardio coachée",
              data: this.series[1].data.reverse()
            },
            {
              name: "Circuit Fonctionnal Training",
              data: this.series[2].data.reverse()
            },
            {
              name: "Cours collectifs",
              data: this.series[3].data.reverse()
            },
            {
              name: "Activités libres",
              data: this.series[4].data.reverse()
            },
            {
              name: "Vidéos",
              data: this.series[5].data.reverse()
            },
            {
              name: "Outdoor",
              data: this.series[6].data.reverse()
            }
          ],
          true,
          true
        );
      }
    }
  },
  props: {
    activities: { type: Array }
  }
};
</script>
